//
.btn-default {
    @include button-variant(white, rgba($black, .125));
}


.btn-toolbar {
    display: flex;
    align-items: start;

    & > .btn-group, & > .btn {
        margin-right: map-get($spacers, 1);
    }
}

.btn-toolbar label, .btn-group label {
	display: inline-block;
	padding: 5px 14px;
	vertical-align: middle;
	margin: 0px;
    font-weight: normal;
}

.main-actions .btn {
    @extend .btn-block;
    margin-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    text-align: left;
}

.btn {
    white-space: nowrap;
}

$border-radius-xs: .1rem !default;
$btn-border-radius-xs: $border-radius-xs !default;
$font-size-xs: $font-size-base * .8 !default;
$line-height-xs: 1.5 !default;
$input-btn-padding-y-xs: .2rem !default;
$input-btn-padding-x-xs: .4rem !default;
$input-btn-font-size-xs: $font-size-xs !default;
$input-btn-line-height-xs: $line-height-xs !default;
$btn-padding-y-xs: $input-btn-padding-y-xs !default;
$btn-padding-x-xs: $input-btn-padding-x-xs !default;
$btn-font-size-xs: $input-btn-font-size-xs !default;
$btn-line-height-xs: $input-btn-line-height-xs !default;


.btn-xs {
    @include button-size($btn-padding-y-xs, $btn-padding-x-xs, $btn-font-size-xs, $btn-line-height-xs, $btn-border-radius-xs);
}