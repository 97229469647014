@use "sass:math";

$table-border-color: #ddd;

.table-header-form {
    // A one-line form before a table, such as one containing actions
    margin-bottom: 8px;
    display: flex;

    @include clearfix();
}

.table-header {
    background-color: $table-header-bg;
    font-size: 14px;
    line-height: 38px;
    padding-left: 12px;
    margin-bottom: 1px;

    .close {
        margin-right: 6px;
        margin-top: 6px;
    }

    .dropdown-menu {
        z-index: 1002;
    }
}

.table-pager {
    @include clearfix();
    margin-top: -9px; // Table has a 19px footer, we need to move up!
    .pager-recordcount {
        @extend .text-muted;
        float: left;
        line-height: $line-height-base;
        padding: $padding-base-vertical $padding-base-horizontal;
    }

    .pager-controls {
        float: right;
    }

    .pagination {
        margin: 0;
    }
}

@media only screen and (max-width: 480px) {
    .table-pager {
        .pager-controls {
            float: none;
            text-align: center;
        }

        .pager-recordcount {
            text-align: center;
            float: none;
            font-size: 0.9em;
        }
    }
}

div.pager-recordcount {
    line-height: 18px;
    padding: 4px 10px;
}

.table-standard td.cell-icon, .table-standard th.cell-icon, td.cell-icon, th.cell-icon, th.cell-checkbox, td.cell-checkbox {
    width: 22px;
    text-align: center;
    white-space: nowrap;

    i.fa, i.fas, i.fal, i.far {
        font-size: 16px;
    }

    label {
        margin-bottom: 0;
    }
}

.table > thead > tr > th.cell-checkbox, .table > tbody > tr > td.cell-checkbox {
    padding: 0;

    label {
        padding: $table-cell-padding;
        margin: 0;
        display: block;
    }
}

.table.table-sm > thead > tr > th.cell-checkbox, .table.table-sm > tbody > tr > td.cell-checkbox {
    label {
        padding-top: math.div($table-cell-padding, 2);
        padding-bottom: math.div($table-cell-padding, 2);
    }
}

.table-standard .table {
    background-color: transparent;
}

/*#region Column Styles */

td.cell-button-1, th.cell-button-1 {
    width: 72px;
}

.table td.cell-buttons {
    padding: 1px 5px;

    .btn {
        @extend .btn-xs;
    }
}

td.cell-right, th.cell-right {
    text-align: right;
}

td.cell-center, th.cell-center {
    text-align: center;
}

td.cell-actions {
    white-space: nowrap;
    width: 20px;
    text-align: right;

    form {
        margin: 0;
        display: inline;
        padding: 0;
    }

    button.btn, a.btn, input.btn {
        @extend.btn-xs;
    }
}

td.cell-narrow {
    white-space: nowrap;
    width: 20px;
}

td.cell-actions-fs {
    white-space: nowrap;
    width: 20px;

    form {
        margin: 0;
        display: inline;
        padding: 0;
    }
}

.cell-truncate {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.cell-nowrap {
    white-space: pre-line;
}

/*#endregion */

.table-fullrowselect {
    @extend .table-hover;

    tbody > tr > td:not(.no-fullrowselect) {
        a:not(.btn) {
            color: inherit;
        }

        a:hover {
            text-decoration: none;
        }
    }

    &.table-fullrowselect-applied {
        // Move the padding from the table cells to the As within them.
        tbody > tr > td:not(.no-fullrowselect) {
            padding: 0;
            line-height: none;

            a:not(.btn) {
                display: block;
                width: 100%;
                height: 100%;
                margin: 0;
                padding: $table-cell-padding;
                //                line-height: $line-height-computed;
            }

            & > div.second-line {
                padding: 0px 8px 8px 8px;
                margin-top: -8px;
            }
        }

        .cell-actions {
            & > .btn {
                margin: 6px;
            }

            & > form {
                margin-right: 6px;
            }
        }

        &.table-sm {
            tbody > tr > td {
                a:not(.btn) {
                    padding: $table-condensed-cell-padding;
                }
            }
        }

        tbody > tr.tr-checkboxrow td {
            padding: 6px;
            cursor: pointer;
        }
    }
}

th, td, .table-bordered {
    border-radius: 0 !important;
}

.table {
    thead tr {
        color: #707070;
        font-weight: normal;
        //background: #F2F2F2;
        //#gradient > .vertical($table-labels-grad-color1, $table-labels-grad-color2);
        th {
            border-top: 0;
            border-bottom: 2px solid rgba(0,0,0,0.14);
            font-weight: 600;

            .fa:first-child {
                margin-right: 2px;
            }

            &:first-child {
                border-left-color: #F1F1F1;
            }

            &:last-child {
                border-right-color: #F1F1F1;
            }
        }
    }

    thead:first-child tr:first-child th {
        //border-top: solid 1px #ddd;
    }

    &.table-bordered thead tr th {
        //border-width:0 0 0 1px;
        //border-color:#DDD;
        vertical-align: middle;

        &:first-child {
            border-left-color: #DDD;
        }
    }

    &.table-hover tbody tr:hover td {
        background-color: #F1F1F1;
    }
}

th, td {
    &.center {
        text-align: center;
    }

    .lbl {
        margin-bottom: 0;

        &:only-child {
            vertical-align: top;
        }
    }
}

table.table-auto-width {
    width: auto;
}

// TODO: Remove when using BS5
// Redo table colours using BS5 techniques as BS4 colours are too stark
@each $color, $value in $table-variants {
    @include bs5-table-variant($color, $value);
}

.table-deleted {
    @extend .text-muted;

    td, th {
        text-decoration: line-through;
    }
}

table.floatThead-table {
    border-top: none;
    border-bottom: none;
    background-color: white;
}

.table > tbody {
    & > tr.expanded-row {
        & > td, & > th {
            border-top: none;
        }
    }
}

td .label, td .badge {
    margin-bottom: 0; // TODO: Remove when labels migrated to badges
}