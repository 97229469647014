﻿@use "sass:math";

/* */
@import "landing-page";
@import "stripe";

.dark {
    .body-content {
        padding-top: 20px;
    }
    // Add a drop-shadow to panels on a plain background
    .panel {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
    }
}

.main-content.dark {
    background-color: transparent;
    border: none;
}

.navbar {
    background-image: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ticket-list .important {
    font-weight: bold;
}

body.amito {
    $amito-bg: $amito-primary1;
    //$amito-bg: #EEF0F3;
    $amito-header-bg: white;
    //$amito-header-bg: $amito-bg;

    background-color: $amito-bg;

    &.dialog-layout {
        background-color: white;

        .card {
            border: solid 1px #ccc;
        }

        .page-header {
            h1 {
                position: relative;
            }

            .title-text {
                position: relative;
                bottom: -6px;
            }
        }
    }

    &.landing-layout {
        background-color: white;
    }

    #header {
        background-color: $amito-header-bg;
        border-bottom: $amito-sep-height solid $amito-bg;
        min-height: 66px + $amito-sep-height;
        //position: absolute;
        @extend .navbar-light;
    }

    #main {
        padding-top: 66px + $amito-sep-height;
    }

    .page-header {
        color: $amito-primary1;
    }

    .dialog-container h2 {
        color: $amito-primary1;
        border-bottom-color: $amito-primary1;
    }

    .sidebar {
        background-color: $amito-bg;
        height: calc(100% - #{61px + $amito-sep-height});
        top: #{61px + $amito-sep-height};
        //position: absolute;
    }

    .nav-list > li > .section > a, .nav-list > li > .section > .dropdown-indicator,
    .nav-list > li .submenu > li > a {
        color: white;
    }

    .nav-list > li.active > a, .nav-list > li.active > a > .arrow, .nav-list > li > a:hover, .nav-list > li > a:hover > .arrow {
        color: $amito-highlight;
    }

    .nav-list > li .submenu > li.active > a, .nav-list > li .submenu > li.active > a > .arrow,
    .nav-list > li .submenu > li > a:hover, .nav-list > li .submenu > li > a:hover > .arrow,
    .nav-list > li .submenu > li.active > a > i:first-child {
        color: $amito-highlight;
    }
}

body.ich {
    background-color: #002c42;

    #header {
        background-color: #002c42;
        min-height: 80px;
    }

    #main {
        padding-top: 80px;
    }

    .sidebar {
        background-color: #002c42;
        top: 75px;
    }

    .nav-list > li {
        box-shadow: initial;
        border-bottom-color: $gray-light;
    }
}

body.crosspoint {
    background-color: #002c42;

    #header {
        background-color: #002c42;
        min-height: 80px;
    }

    #main {
        padding-top: 80px;
    }

    .sidebar {
        background-color: #002c42;
        top: 75px;
    }

    .logo > a {
        max-width: 250px;

        @include media-breakpoint-down(xs) {
            overflow: hidden;
            max-width: 175px;
            transition: max-width 0.2s ease-in-out;
        }
    }
}

body.ch {
    background-color: #191d39;

    #content a:not(.btn) {
        color: lighten(#191d39, 14%);
    }

    #header {
        background-color: #191d39;
        min-height: 80px;
    }

    #main {
        padding-top: 80px;

        & .page-header {
            color: #191d39;
        }
    }

    .sidebar {
        top: 75px;
        background-color: #191d39;

        li {
            & .submenu > li.active > a > .fa:first-child {
                color: #ff9400;
            }

            & > a:focus, & > a:active, & > a:hover {
                color: #ff9400;

                & > .arrow {
                    color: #ff9400;
                }
            }

            &.active {
                & > a {
                    color: #ff9400;

                    & > .arrow {
                        color: #ff9400;
                    }
                }
            }

            &.open > a, &.open > a:focus {
                color: #ff9400;

                & > .arrow {
                    color: #ff9400;
                }
            }
        }
    }
}

.package_container {
    background-color: white;
}

.panel.panel-packages-item {
    margin-top: 20px;

    .panel-heading {
        background-color: rgba(0, 0, 0, 0.01);
        background-image: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    &:hover {
        cursor: pointer;

        .panel-heading {
            background-color: #eeeeee;
        }
    }
}

.panel.panel-packages-item.selected_item {
    border-color: #0e6fff;
    color: #0e6fff;

    .panel-heading {
        background-color: #F5F9FF;
    }

    &:hover {
        cursor: default;

        .panel-heading {
            background-color: #F5F9FF;
        }
    }
}

.addon_container {
    max-height: 50px;
}

.region_container .panel.panel-packages-item.selected_item:hover {
    cursor: pointer;
}

.addon_container .panel.panel-packages-item.selected_item:hover {
    cursor: pointer;
}

.payment-row {
    &:hover {
        cursor: pointer;
        background-color: #F5F9FF;
        color: #0e6fff;
    }
}

.selected_payment {
    background-color: #F5F9FF;
    color: #0e6fff;

    &:hover {
        cursor: default;
        background-color: #F5F9FF;
        color: #0e6fff;
    }
}

.nav-list.tree {
    > li {
        > .submenu { //the first level submenu
            &:before {
                bottom: math.div($submenu-item-height, 2) - 1;
            }
        }
    }
}

ul.pricing-info {
    padding-left: 0;

    li {
        list-style-type: none;
    }
}

.packages-item {
    border-radius: 4px;
    border: 1px solid #ddd;
    margin-top: 20px;

    .item-heading {
        background-color: rgba(0, 0, 0, 0.01);
        background-image: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        padding-top: 10px;
        padding-bottom: 10px;
    }

    &:hover {
        cursor: pointer;

        .item-heading {
            background-color: #eeeeee;
        }
    }

    &.selected_item {
        border-color: #0e6fff;
        color: #0e6fff;

        .item-heading {
            background-color: #F5F9FF;
        }

        &:hover {
            cursor: default;

            .item-heading {
                background-color: #F5F9FF;
            }
        }
    }
}

.g-recaptcha {
    display: inline-block;
}

body.register .dialog-container {
    width: 600px;
    padding-top: 0;

    @media (min-width: 768px) {
        .control-label {
            width: 140px;
        }

        .controls {
            width: 440px;
        }
    }

    #register_form input[type=text], #register_form input[type=email], #register_form input[type=password] {
        display: inline-block;
    }
}