ul.resource-usage {
    li {
        display: inline-block;
        margin-right: 10px;
        height: 100px;
        text-align: center;
    }

    text-align: right;
}

ul.resource-summary-iconic {
    margin-bottom: 0;

    li {
        display: inline-block;
        padding-right: 30px;
        line-height: 32px;
        font-size: 1.2em;

        & > i.fal, & > i.fa {
            font-size: 2em;
            padding-right: 5px;
        }

        &:last-child {
            padding-right: 0;
        }
    }
}