$status-ok: #88b917;
$status-warning: #ff9a13;
$status-critical: #e00b3d;
$status-pending: #00a499;
$status-disabled: #818285;
$status-unknown: #818285;

.monitoring-overview {
    @extend .badge;
    font-size: 85%;
    font-weight: normal;
    min-width: 20px;
    display: inline-block;

    &:before, &:after {
        font-family: 'Font Awesome 6 Pro';
        font-style: normal;
        font-weight: 400;
        width: 1em;
        display: inline-block;
        text-align: center;
    }

    > span {
        padding-left: 0.1em;
    }

    a, a:hover, a:visited {
        color: inherit;
        text-decoration: none;
    }

    &.downtime:after {
        content: fa-content($fa-var-clock);
    }

    &.OK {
        @include badge-variant($status-ok);

        &:before {
            content: fa-content($fa-var-check);
        }
    }

    &.Warning {
        @include badge-variant($status-warning);

        &:before {
            content: fa-content($fa-var-exclamation);
        }

        &.acknowledged, &.downtime {
            @include badge-variant(lighten($status-warning, 33%));
        }
    }

    &.Critical {
        @include badge-variant($status-critical);

        &:before {
            content: fa-content($fa-var-exclamation-circle);
        }

        &.acknowledged, &.downtime {
            @include badge-variant(lighten($status-critical, 40%));
        }
    }

    &.Disabled {
        @include badge-variant($status-disabled);
    }

    &.Pending {
        @include badge-variant($status-pending);

        &:before {
            content: fa-content($fa-var-ellipsis-h);
        }
    }

    &.Unknown {
        @include badge-variant($status-unknown);

        &:before {
            content: fa-content($fa-var-question-circle);
        }
    }

    &.nodata {
        @include badge-variant($status-unknown);
        opacity: 0.5;
    }

    &.acknowledged {
        //background-color: transparent;
        //color: $body-color;
    }

    .ackicon {
        display: none;
    }

    &.acknowledged {
        .ackicon {
            display: inline;
        }
    }
}

.popover-monitoring {
    max-width: 526px;
}