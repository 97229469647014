/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
/// <reference path="variables-custom.less" />

footer {
	text-align: right;
	color: $gray-light;

	a {
		color: $gray-light;
	}

	p {
		font-size: 0.8em;
	}

	position: absolute;
	bottom: 0;
	width: 100%;
	height: $footer-height + $footer-padding-top + $footer-padding-bottom;
	padding-top: $footer-padding-top;
	padding-bottom: $footer-padding-bottom;
	padding-right: 30px;

	.f-menu {
		display: block;
		width: 100%;
		@extend .list-inline;
		margin-top: 8px;

		& > li > a {
			color: #a2a2a2;

			&:hover {
				color: #777;
			}
		}
	}
}

@include media-breakpoint-up(md) {
	body.show-sidebar {
		#footer {
			padding-left: $sidebar-width;
		}
	}
}

@include media-breakpoint-down(sm) {
	footer {
		padding-right: 15px;
	}
}


#cookieConsent {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	margin: 0 !important;
	z-index: 999;
	opacity: 0;
	visibility: hidden;
	border-radius: 0;
	transform: translateY(100%);
	transition: all 500ms ease-out;
	background: black;
	color: #ecf0f1;
	text-align: center;

	&.show {
		opacity: 1;
		visibility: visible;
		transform: translateY(0%);
		transition-delay: 1000ms;
	}

	.acceptcookies {
		margin-left: 10px;
		vertical-align: baseline;
	}
}