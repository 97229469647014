/// <reference path="../../jspm:bootstrap-less/bootstrap/mixins.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
/// <reference path="mixins-custom.less" />
/// <reference path="variables-custom.less" />

$ace: #f7f7f7;

#header {
    @extend .navbar;
    @extend .navbar-expand;
    @extend .fixed-top;

    box-shadow: $header-shadow;
    min-height: $header-height;
    @include user-select(none);
    background-color: $header-bg;
    
    a.navbar-brand {
        padding: 0px 10px;
    }

    #loading-indicator {
        visibility: hidden;
    }

    @include media-breakpoint-down(lg) {
        padding-left: 0;
    }
}



#menu-trigger {
    cursor: pointer;
    position: relative;

    padding: $navbar-toggler-padding-y $navbar-toggler-padding-x;
    @include font-size($navbar-toggler-font-size);
    line-height: 1;
    background-color: transparent; // remove default button style
    border: $border-width solid transparent; // remove default button style
    @include border-radius($navbar-toggler-border-radius);
    width: 65px;

    &:hover, &:focus {
        text-decoration: none;
    }

    &:before {
        content: "";
        position: absolute;
        top: -2px;
        left: 10px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: rgba(255, 255, 255, 0.22);
        @include transition(all 300ms);
        transform: scale(0);
        z-index: 0;
    }

    &.open {
        &:before {
            transform: scale(1);
        }
    }
}

.top-menu {
	list-style: none;
	padding: 0;

	@include media-breakpoint-down(xs) {
		.dropdown-menu-lg {
			width: calc(100% - 28px) !important;
		}

		.dropdown-menu {
			right: 14px;
			top: 55px;
		}
	}
}

.top-menu > li {
	display: inline-block;
	margin: 0 1px;
	vertical-align: top;
	//min-width: 50px;
	padding: 0 11px;
	color: $header-color;

	@include media-breakpoint-down(xs) {
		position: static !important;
		padding: 0 8px;
	}

	.dropdown-menu-lg {
		padding: 0;

		.lv-body {
			min-height: 295px;
			overflow-x: hidden;
		}
	}

	@include hover-pop($header-hover-bg, 2px, 250ms, 0);

	.spinner-border {
		width: 1.5rem;
		height: 1.5rem;
		margin-top: 0.25rem;
	}

	& > a {
		color: $header-color;
		display: block;
		text-align: center;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}

		& > i {
			font-size: 20px;
			line-height: 36px;
		}

		& > .tm-label {
			line-height: 35px;
			white-space: nowrap;
			padding: 0 10px;
			font-size: $font-size-base + 1;
			//text-transform: uppercase;
		}

		& > .tmn-counts {
			position: absolute;
			font-style: normal;
			background-color: red;
			padding: 1px 5px;
			border-radius: 2px;
			right: 7px;
			top: -3px;
			font-size: 10px;
			line-height: 15px;
		}
	}

	.user-info {
		max-width: 300px;
		display: inline-block;
		overflow: hidden;
		-ms-text-overflow: ellipsis;
		-o-text-overflow: ellipsis;
		text-overflow: ellipsis;
		white-space: nowrap;
		vertical-align: top;
		text-align: left;
		padding-left: 4px;
		@extend .d-none;
		@extend .d-sm-inline-block;

		& > small {
			display: block;
			font-size: 11px;
			line-height: 12px;
		}

		& > span {
			display: block;
			line-height: 20px;
			padding-bottom: 4px;
		}
	}
}

#notifications {
	position: relative;

	.lv-body {
		overflow-x: hidden;
	}

	&:before {
		content: "";
		position: absolute;
		width: 100%;
		height: calc(100% - 70px);
		//background:
		@include transition(all 400ms);
		@include scale-rotate(0, -180deg);
		opacity: 0;
		top: 42px;
	}

	&.empty:before {
		@include scale-rotate(1, 0deg);
		opacity: 1;
	}
}

/* Full Screen */
:-webkit-full-screen [data-action="fullscreen"] {
	display: none;
}

:-moz-full-screen [data-action="fullscreen"] {
	display: none;
}

:-ms-fullscreen [data-action="fullscreen"] {
	display: none;
}

:full-screen [data-action="fullscreen"] {
	display: none;
}

:fullscreen [data-action="fullscreen"] {
	display: none;
}

body.loading {
	cursor: progress !important;
	a:hover {
		cursor: progress !important;
	}
	#header #loading-indicator {
		visibility: visible;
	}
}

#main {
    padding-top: $header-height;
    padding-bottom: $footer-height + $footer-padding-top + $footer-padding-bottom;
}

#top-search-wrap {
	position: absolute;
	top: -1 * $header-height;
	left: 0;
	width: 100%;
	height: $header-height;
	background-color: white;
	border-bottom: solid 1px #ccc;
	box-shadow: 0 0 10px rgba(51, 51, 51, 0.38);
	@include transition(all 300ms);
	opacity: 0;
	z-index: 30;

	.tsw-inner {
		position: relative;
		padding: 15px;
		max-width: 700px;
		display: block;
		margin: 0 auto;
	}
}

#top-search-wrap, #top-menu-search-wrap {

	.twitter-typeahead {
		display: block !important;
	}

	input[type="text"] {
		border: 0;
		height: 40px;
		padding: 0 10px 0 55px;
		font-size: 18px;
		width: 500px;
		border-radius: 2px;
		background-color: darken($ace, 3%);
		width: 100%;
	}

	.tsw-inner {
		#top-search-close {
			position: absolute;
			top: 15px;
			font-size: 23px;
			font-style: normal;
			width: 45px;
			text-align: center;
			border-radius: 2px 0px 0px 2px;
			cursor: pointer;
			left: 15px;
			height: 40px;
			padding-top: 9px;
			z-index: 1;
		}

		#top-search-busy {
			position: absolute;
			top: 15px;
			font-size: 23px;
			font-style: normal;
			width: 45px;
			text-align: center;
			border-radius: 2px 0px 0px 2px;
			right: 15px;
			height: 40px;
			padding-top: 9px;
			z-index: 1;
		}
	}

	#top-search-close {

		&:hover {
			background-color: darken($ace, 8%);
		}

		@include media-breakpoint-down(xs) {
			right: 7px;
		}
	}
}

#top-menu-search-wrap {
	padding-left: 20px;

	.tsw-inner {
		position: relative;
		max-width: 700px;
		display: block;
		margin: 0 auto;
		margin-top: -2px;
		white-space: nowrap;


		& > i.fa {
			top: 0;
			left: 0;
		}

		button {
			line-height: 28px;
			margin-top: -4px;
		}
	}
}

.search-toggled {
	#top-search-wrap {
		top: 0;
		opacity: 1;
	}
}

/* Full Width Layout */
#toggle-width {
	@include media-breakpoint-up(lg) {
		.toggle-switch {
			margin: 9px 30px 0 0;

			.ts-helper {
				height: 11px;
				width: 33px;

				&:before {
					width: 20px;
					height: 20px;
					top: -5px;
				}
			}

			input:checked + .ts-helper {
				background: rgba(0, 0, 0, 0.26);

				&:before {
					left: 18px;
					background: #fff;
				}
			}
		}
	}

	@include media-breakpoint-up(lg) {
		display: none;
	}
}

.show-sidebar {
	@include media-breakpoint-up(lg) {
		#header {
			padding-left: 15px;
		}

		#menu-trigger {
			display: none;
		}
	}
}