﻿/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
@import "../_common/variables-custom";




$amito-primary1: #14213d;
$amito-primary2: #0e68b4;
$amito-primary3: #00b0ff;
$amito-highlight: #ffc000;
$amito-sep-height: 10px;

/*$amito-primary1: #3D375A;
$amito-primary2: #5E8AF3;
$amito-primary3: #9FBCFF;
$amito-highlight: #ffc000;
$amito-sep-height: 10px;
*/

$primary: #4874DF;
    
$body-bg: $gray-lighter;
$main-bg: white;

// Header
$header-bg: $body-bg;
$header-shadow: none;
$header-hover-bg: rgba(0, 0, 0, 0.1);

// Sidebar
$sidebar-width: 199px;                  // Width when open
$sidebar-bg: transparent;
$sidebar-side-margin: 10px;                // Gap either side of maximised menu
$sidebar-shadow: none;

// Background colours
$sidebar-bg: $body-bg;

// Styling for all top-level menu items
$menu-icon-size: 22px;
$menu-icon-width: 32px;
$menu-item-border-color: darken($body-bg, 10%);
$menu-border-shadow-color: white;
$menu-item-box-shadow: 0 2px 1px -1px $menu-border-shadow-color;

$menu-item-active-border-right: transparent;
$menu-item-active-arrow-color: transparent;

$submenu-border: transparent;
$submenu-margin-top: -10px;     // Move up into the main item
$submenu-icon-width: 30px;
$menumin-btn-bg: $body-bg;
 
// Go back to square, smaller inputs
// @input-border-radius: 0px;

$table-header-bg: transparent;

$dialog-page-header-border-color: $gray-light;
$dialog-page-border-width: 1px;
$dialog-page-border-radius: 4px;

$nav-item-bg: transparent;
$nav-item-color: $brand-highlight-color;

$footer-height: 70px;
$footer-padding-vertical: 10px;

$submenu-left-border: $gray-light;