/// <reference path="../../jspm:bootstrap-less/bootstrap/alerts.less" />
/// <reference path="variables-custom.less" />

.has-error {
    /*input[type="text"], input[type="password"], input[type="datetime"],
    input[type="datetime-local"], input[type="date"], input[type="month"],
    input[type="time"], input[type="week"], input[type="number"],
    input[type="email"], input[type="url"], input[type="search"],
    input[type="tel"], input[type="color"], select, textarea {
    }*/
    input[type="checkbox"] {
        border: 0 none;
    }

	input[type="text"], input[type="password"], input[type="datetime"],
    input[type="datetime-local"], input[type="date"], input[type="month"],
    input[type="time"], input[type="week"], input[type="number"],
    input[type="email"], input[type="url"], input[type="search"],
    input[type="tel"], input[type="color"], select, textarea {
		border: 1px solid $validation-error-color;
		background-color: #ffeeee;
    }
}

input.has-error, select.has-error, textarea.has-error {
	border: 1px solid $validation-error-color !important;
	background-color: #ffeeee !important;
}

.input-validation-error {
    border: 1px solid $validation-error-color;
    background-color: #ffeeee;
}

.validation-summary-errors {
    @extend .alert;
    @extend .alert-danger;

    &::before {
        float: left;
        padding-top: 2px;
    }

    ul::before {
        display: block;
        font-weight: bold;
        content: "The following problems have been detected - please correct them and retry:";
        margin: 0px 10px 10px -30px;
    }

    &.validation-summary-system-error {
        ul::before {
            content: "An error was encountered while handling your request";
        }
    }
}

.field-validation-error {
    color: $validation-error-color;
}

.field-validation-valid {
    display: none;
}

input[type="text"].input-validation-error, input[type="password"].input-validation-error, input[type="datetime"].input-validation-error,
input[type="datetime-local"].input-validation-error, input[type="date"].input-validation-error, input[type="month"].input-validation-error,
input[type="time"].input-validation-error, input[type="week"].input-validation-error, input[type="number"].input-validation-error,
input[type="email"].input-validation-error, input[type="url"].input-validation-error, input[type="search"].input-validation-error,
input[type="tel"].input-validation-error, input[type="color"].input-validation-error, select.input-validation-error, textarea.input-validation-error {
    border: 1px solid $validation-error-color;
    background-color: $validation-error-bg;
}


.validation-summary-valid {
    display: none;
}
