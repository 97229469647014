/// <reference path="variables-custom" />

$list-item-padding: 10px;

a.list-activity-item, .list-activity-item > a {
    display: block;
    padding: $list-item-padding;
    color: $body-color;

    &:hover {
        background-color: $list-group-hover-bg;
        text-decoration: none;
    }

    text-decoration: none;
}

.list-activity-item {
    display: block;
    min-height: 36px;
    padding: 0;
    border-bottom: dotted 1px #CCC;
    position: relative;
    white-space: nowrap;

    .item-info {
        color: #888;
        font-size: 11px;
        margin-left: 5px;
    }

    .item-title {
        font-weight: bold;
        display: block;
        //font-size: 13px;
        @include text-truncate();
    }

    .item-subtitle {
        display: block;
        font-size: 12px;
        @include text-truncate();
    }

    .labels {
        float: right;

        .label {
            font-size: 10px;
            vertical-align: text-top;
        }
    }

    .preview {
        color: #888;
        font-size: 10px;
        display: block;
        @include text-truncate();
    }
}

td .list-activity-item {
	border-bottom: none;
}