/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/mixins.less" />

.tt-menu {
  width: 100%;
  margin-top: 15px;
  padding: 5px 0;
  background-color: $dropdown-bg;
  border: 1px solid #ccc;
  border: 1px solid $dropdown-border;
  border-right-width: 2px;
  border-bottom-width: 2px;
  z-index: 29 !important;
  
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.38);

  -webkit-background-clip: padding-box;
     -moz-background-clip: padding;
          background-clip: padding-box;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;
}

.tt-suggestion + .tt-suggestion {
  border-top: 1px solid #ccc;
}

.tt-suggestion.tt-cursor{
  color: $dropdown-link-hover-color;
  @include gradient-y($dropdown-link-hover-bg, darken($dropdown-link-hover-bg, 5%));
}

.tt-suggestion.tt-cursor a {
  color: $dropdown-bg;
}

.tt-suggestion p {
  margin: 0;
}