@import "blueimp-gallery/css/blueimp-gallery.min.css";
@import "blueimp-gallery/css/blueimp-gallery-indicator";

.blueimp-gallery {
    background: rgba(0,0,0,0.75);

    & > .slides {
        & > .slide-loading {
            background: url(images/_app/blueimp/loading.gif) center no-repeat;
        }

        .slide img {
            background-color: white; // Deal with people adding attachments with transparent background
        }
    }
}

.blueimp-gallery-display, .blueimp-gallery-svgasimg {
    & > .next {
        background-image: url(images/_app/blueimp/next.png);
    }

    & > .prev {
        background-image: url(images/_app/blueimp/prev.png);
    }

    & > .close {
        background-image: url(images/_app/blueimp/close.png);
    }
}

.blueimp-gallery-smil > .slides > .slide-loading {
    background-image: url(images/_app/blueimp/loading.svg);
}

.blueimp-gallery-svgasimg {
    & .close {
        background-image: url('images/_app/blueimp/close.svg');
    }
}