/// <reference path="variables-custom" />

//
// Variables which control the sidenav behaviour
//

// Dimensions...
$sidebar-width: 189px; // Width when open
$sidebar-side-margin: 10px; // Gap either side of maximised menu
$sidebar-expanded-top-margin: 40px;
$sidebar-min-width: 42px; // Width when minimised

// Main sidebar appearance
$sidebar-bg: $header-bg;
$sidebar-border-right: transparent;
$sidebar-shadow: 0 0 10px rgba(51, 51, 51, 0.38);

// Styling for all top-level menu items
$menu-icon-size: 18px;
$menu-icon-width: 30px;
$menu-font-size: 14px;
$menu-badge-font-size: 12px;
$menu-item-height: 55px;
$menu-item-border-color: darken($body-bg, 10%);
$menu-item-border-shadow-color: white;
$menu-item-border-top-width: 0px;
$menu-item-border-bottom-width: 1px;
$menu-item-box-shadow: none;
$menu-subarrow-color: $menu-item-color;
$menu-subarrow-size: 16px;
$menu-item-bg: transparent;


// Open top-level items
$menu-open-bg: transparent;

// Current top-level item
$menu-item-active-bg: transparent;
$menu-item-active-color: $brand-highlight-color;
$menu-item-active-border-right: $menu-item-active-color;
$menu-item-active-arrow-color: $brand-highlight-color;
$menu-focus-color: $brand-highlight-color;

// Hovering over this item
$menu-item-hover-bg: transparent;
$menu-item-hover-indicator: transparent;

// Submenu Container Appearance
$submenu-bg: transparent;
$submenu-minimised-bg: white;
$submenu-border: #3f4e54;
$submenu-left-border: #3f4e54;
$submenu-margin-top: 0px;

// Styling for all submenu items
$submenu-icon-size: 16px;
$submenu-icon-width: 22px;
$submenu-font-size: 13px;
$submenu-item-color: #999;
$submenu-item-border: transparent;
$submenu-item-hover: #4B88B7;
$submenu-item-hover-bg: $menu-item-hover-bg;
$submenu-item-height: 34px;

$submenu-subarrow-size: 14px;
$submenu-border: #E5E5E5;
$submenu-left-border: mix(#BCCFE0, #7EAACB);
$submenu-left-border-style: dotted;
$submenu-item-hover-bg: $menu-item-hover-bg;
$submenu-active-left-border: $submenu-left-border;

$submenu-item-hover: #4B88B7;
$submenu-item-active-icon: $menu-item-active-color;

$submenu-active-left-border: mix(mix(#BCCFE0, #7EAACB), #7EAACB);


//
// Minimised Menu Items
//
$menumin-btn-bg: #F3F3F3;
$menumin-btn-border: #E0E0E0;
$menumin-icon-color: #AAA;
$menumin-icon-border: #BBB;
$menumin-icon-bg: #FFF;
$menumin-bg: #F5F5F5;
$menumin-border: #CCC;
$menumin-text-border: #DDD;
$menumin-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);

$shortcuts-bg: $navbar-inverse-bg;
$shortcuts-border: #DDD;