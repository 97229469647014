.feed-list {
    padding-left: 0px;

    .network-feed, .power-feed {
        display: flex;
        flex-direction: row;
    }

    .feed-icon {
        margin: 2px 4px 2px 0px;
        float: left;
    }

    .feed-summary-graph {
        margin: 6px 4px 0px 4px;
        float: right;
        height: 52px;
        font-size: 1px;
        color: transparent;
    }

    .feed-name {
        font-weight: bold;
        font-size: 1.2em;
        margin-top: 4px;
    }

    ul.feed-addresses {
        display: inline;
        padding-left: 0px;

        li {
            list-style-type: none;
            display: inline-block;
            font-size: 0.9em;

            &::after {
                content: ', ';
            }

            &:last-child::after {
                content: '';
            }
        }
    }


    & > li, & > div {
        list-style-type: none;
        /*& > div, & > ul {
			display: inline-block;
		}*/
        & > .btn-group {
            float: right;
            margin: 10px 4px;
        }
    }

    .power-feed {
        .feed-name {
            display: inline-block;
            line-height: 44px;
        }

        .infobox-data {
            padding-top: 4px;
            min-width: 100px;
            font-size: 13px;
            line-height: 21px;

            .infobox-data-number {
                display: block;
                font-size: 22px;
                margin: 2px 0 4px;
            }

            .infobox-data-item {
                display: inline-block;
                margin-right: 5px;
            }
        }


        .power-reading {
            display: inline-block;

            & > span {
                display: inline-block;
                padding-left: 20px;
                font-size: 16px;
                min-width: 80px;
                text-align: right;
            }
        }
    }

    .unit {
        color: #888;
        font-size: 0.8em;
    }
}

.list-group-notes {

	.author {
		padding-top: 5px;
		display: block;
		font-size: 0.8em;
		opacity: 0.9;
	}

}

@import "services/resourceusage";