/// <reference path="../../bootstrap/variables.less" />
/// <reference path="../../bootstrap/mixins.less" />
/// <reference path="variables-custom.less" />

.dialog-container {
	width: $dialog-container-width;
	margin: $dialog-margin-top auto auto;

	hgroup.page-header {
		//padding-bottom: 8px;
		h1 {
			line-height: 38px;
			text-align: center;
		}

		.brand {
			margin-right: 12px;
		}

		a:hover {
			text-decoration: none;
		}

		border-bottom: solid 1px $dialog-page-header-border-color;
	}

	.dialog {
		background-color: white;
		padding: 20px 25px 20px;
		margin: 0 auto 25px;
		border-radius: 2px;
	}

	.row {
		margin-left: 0px;
		margin-right: 0px;
	}

	.widget-box {
		border-bottom: none;
	}

	.widget-body {
		border: solid $dialog-page-border-width $dialog-page-header-border-color;
		border-radius: $dialog-page-border-radius;
	}

	.widget-main {
		padding: 18px 36px 36px;
	}
	// .widget-body {
	// 	//border: none;
	// }
	h2 {
		font-weight: lighter;
		font-size: 19px;
		color: $brand-highlight-color;
		border-bottom: solid 1px;
		border-bottom-color: lighten($brand-highlight-color, 40%);
		padding-bottom: 8px;
		margin: 16px 0;
	}

	input[type=text], input[type=email], input[type=password], input[type=number] {
		@extend .form-control;
		display: block;
	}

	input[type=number] {
		&::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}

		-moz-appearance: textfield;
	}

	.toolbar {
		margin-top: 9px;
		border-top: solid 2px $dialog-page-header-border-color;
		background-color: $gray-lighter;

		> div {
			padding: 9px 0 11px;

			> a {
				font-size: 15px;
				margin-left: 15px;
			}
		}
	}
}

@media (max-width: 480px) {
	.dialog-container {
		margin-top: 0px;

		// .page-header .brand {
		// 	//display: block;
		// }

		.widget-main {
			padding: 9px 18px 18px;
		}

		.widget-box {
			border: none;
			border-radius: 0px;
		}

		width: 100%;
	}
}
