/// <reference path="variables-custom.less" />

div.panel {
    @extend .card;
    @extend .mb-4;
}

div.panel-heading {
    @extend .card-header;
    display: flex;
    flex-direction: row;

    & > h3 {
        flex-grow: 1;
        font-size: 16px; // TODO: Replace with REM
        margin-bottom: 0;

        a {
            color: inherit;
        }
    }

    & > .btn-toolbar {
        flex-shrink: 1;

        & > .btn, & > .btn-group {
            @extend .ml-1;
        }

        .btn {
            @extend .btn-sm;
        }
    }
}

div.panel-body {
    @extend .card-body;
}

.panel-heading.with-toolbar {
    h3.panel-title {
        line-height: $line-height-base * 1.3;
        font-size: 16px;
    }
}

.panel-body {
    &.no-padding {
        padding: 0;

        table {
            margin-bottom: 0;
        }

        & > .alert {
            margin: 5px;
        }

        .list-group {
            .list-group-item {
                &:first-child {
                    border-radius: 0;
                    border-top: 0;
                }

                &:last-child {
                    border-radius: 0;
                    border-bottom: 0;
                }

                border-left: 0;
                border-right: 0;
            }

            margin-bottom: 0;
        }
    }

    @include media-breakpoint-only(sm) {
        padding: 5px;
    }

    @include media-breakpoint-down(xs) {
        padding: 2px 0px;
    }

    &.note {
        background-color: #FFFF99
    }
}

/*@include media-breakpoint-up(sm) {
	// Prevent padding shrinking in wider panels as they are fixed width
	.modal-lg {
		.panel-body:not(.no-padding) {
			padding: $panel-body-padding;
		}
	}
}*/