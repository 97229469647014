.pie-chart {
    line-height: 100%;
    position: relative;

    canvas, .value, .textvalue {
        position: absolute;
        top: 0;
        left: 0;
    }

    .value, .textvalue {
        line-height: 100%;
        width: 100%;
        height: 100%;
        text-align: center;

        &.percent:after {
            content: '%';
        }
    }
}

table.time-graph-stats {
    border: none;
    margin: 6px auto 0;
    td {
        border: none;
        padding: 1px 5px;
        font-size: 12px;
    }
}