/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/mixins.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/utilities.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/wells.less" />
/// <reference path="mixins-custom.less" />
/// <reference path="tables-custom.less" />

.ticket-list-toolbar {
    @extend .btn-toolbar;
    display: flex;
    flex-wrap: nowrap;
    align-items: flex-start;
    background-color: transparent;

    @include media-breakpoint-up(sm) {
        float: right !important;
        position: relative;
        margin-top: 0px !important;
        margin-bottom: 0px;
        z-index: 100;
        padding-bottom: 13px;
        border-bottom: solid 1px #C5D0DC;
    }

    input[type=search] {
        width: 140px;
    }

    @include media-breakpoint-down(xs) {
        padding-left: 5px;
        padding-right: 5px;
        margin-left: -1px;
        margin-right: -1px;
        margin-bottom: -1px;

        .btn-group {
            flex-basis: auto;
            flex-shrink: 0;
        }

        .input-group {
            flex-shrink: 1;
            flex-grow: 1;
            display: block;

            .form-inline {
                display: block;
            }

            input {
                display: block;
                width: 100%;
            }
        }
    }
}

.ticket-header {
    @extend .well;
    margin-bottom: 10px;
    padding: 8px 10px;
    margin-left: 0;
    margin-right: 0;
    font-size: 13px;

    .row {
        margin-left: 0;
        margin-right: 0;

        div[class^="col-"] {
            margin-left: 0;
            margin-right: 0;
            padding-left: 4px;
            padding-right: 4px;
        }
    }

    dl {
        &.pull-right {
            dd {
                min-width: 150px;
            }
        }

        dd {
            margin-bottom: 4px;
        }

        margin-top: 0;
        margin-bottom: 0;
    }
    // Redefine this here so that it doesn't go responsive
    .dl-horizontal {
        dt {
            float: left;
            width: 75px;
            clear: left;
            text-align: right;
            @include text-truncate();
        }

        dd {
            margin-left: 90px;
            @include clearfix(); // Clear the floated `dt` if an empty `dd` is present
        }
    }

    .row:last-child dl {
        margin-top: 0;
    }

    a.label:hover {
        text-decoration: none;
    }

    .badge {
        padding: 0.75em 1em;
    }
}

.popover-body .dl-horizontal {
    dt {
        width: 90px;
    }

    dd {
        margin-left: 105px;
    }
}

@media only screen and (max-width: 480px) {
    .ticket-header {
        padding: 5px;
    }
}

.ticket-header + .btn-toolbar {
    margin-bottom: 6px;
}

.ticket-entry {
    position: relative;
    clear: both;
    overflow: hidden;
    padding-top: 4px;
    padding-bottom: 3px;
    border-bottom: solid 1px #DDD;
    @include clearfix();
}

.ticket-entries {
    border-top: solid 1px #DDD;
    margin-bottom: 8px;
}

.ticket-entry-header {
    position: relative;
    height: 20px;
    line-height: 20px;
    white-space: nowrap;
    cursor: pointer;
    padding: 0 10px 4px 8px;

    h3 {
        position: relative;
        top: 1px;
        left: 10px;
        display: inline-block;
        margin-right: 3em;
        font-size: 14px;
        line-height: $line-height-base * 1.2;

        small {
            font-size: 13px;
        }
    }

    .details {
        overflow: hidden;

        & > i {
            margin-right: 4px;
            font-size: 16px;
        }
    }

    .preview {
        color: $gray-light;
        display: inline;
    }

    .date-brief {
        display: inline;
    }

    .date-full {
        display: none;
    }

    .right-details {
        @extend .pull-right;
        padding-left: 8px;

        & > i {
            margin-right: 4px;
        }
    }
}

.ticket-entry-staff {
    @extend .bg-light;

    .ticket-entry-content {
        @extend .bg-light;
    }
}

.ticket-entry-securenote {
    @extend .bg-warning;

    .ticket-entry-content {
        @extend .bg-warning;
    }

    .ticket-entry-header {
        cursor: default;
    }
    
    pre {
        margin-bottom: 0;
    }
}

.ticket-note {
    background-color: #FFFF99;

    // .ticket-entry-header {
    // }

    .ticket-entry-content {
        background-color: inherit;
    }

    &.deleted {
        opacity: 0.5;
    }
}

.ticket-entry-content {
    background: #FFF;
    padding: 5px 15px 10px;
    display: none;

    > span {
        white-space: pre-wrap;
    }

    .ticket-email-details {
        font-size: 0.9em;
        margin-bottom: 1em;
        margin-top: -2px;
        @extend .text-muted;

        & > .recipient-list {
            padding-right: 10px;
        }
    }
}

ul.ticket-notification-list {
    padding: 0;
    margin: 0;
    li {
        list-style-type: none;
        display: inline-block;
    }
    
}

.ticket-entry:not(.ticket-entry-expanded) {
    .visible-expanded {
        display: none;
    }

    .visible-expanded-inline {
        display: none;
    }
}

.ticket-entry-expanded {
    .preview {
        display: none;
    }

    .ticket-entry-content {
        display: block;
    }

    .date-brief {
        display: none;
    }

    .date-full {
        display: inline;
    }

    .visible-expanded {
        display: block;
    }

    .visible-expanded-inline {
        display: inline;
    }
}

.ticket-entry-header + .ticket-entry-content {
    border-top: none;
    @include border-radius-individual(0px, 5px, 5px, 0px);
}

.ticket-reply {
    margin-top: 1em;

    textarea.form-control {
        min-height: 94px;
    }

    textarea.note {
        background-color: #FFFF99;
    }

    .tab-content {
        padding-top: 0;
        padding-bottom: 10px;
    }

    .btn-toolbar {
        @include clearfix();
        margin-top: 10px;
        margin-bottom: 10px;

        label {
            font-weight: normal;
        }
    }
}

.ticket-list div.ticket-subject {
    @extend .cell-truncate;

    .preview {
        color: $gray-light;
        font-weight: normal !important;
    }
}

.dashboard table.ticket-list {
    margin-bottom: 0;
}

div.ticket-entry-attachments {
    border-top: dotted 1px #EEE;
    margin-top: 4px;
    padding: 4px 0;

    span {
        white-space: inherit;
    }

    div.file-attachment, div.image-attachment {
        display: inline-block;
        border: dotted 1px #eee;

        a:hover {
            text-decoration: none;
        }
    }
}

.draft-info {
    label {
        color: $gray-light;
        font-size: 0.9em;
    }

    .btn-toolbar {
        margin-top: 4px;
        margin-bottom: 0;
    }
}

.tag-list .label {
    margin-right: 0.3em;
    padding: .4em .6em .3em;
}