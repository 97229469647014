/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
$brand-highlight-color: #4B9CBE;

$gray: #555;
$gray-lighter: #eee;
$gray-darker: #222;
$gray-dark: #333;
$gray-light: #777;
$body-bg: #fff;
$navbar-inverse-bg: $gray-darker;

//$amito-blue-900: #3D375A;
//$amito-blue: #4874DF;
//$amito-: #0e68b4;
//$amito-: #00b0ff;
//$amito-highlight: #ffc000;

$primary: #5E8AF3;

// Shrink default font size
$font-size-base: 0.85rem;
$font-size-small: 0.75rem;

$padding-base-vertical:          6px;
$padding-base-horizontal:        10px;

$padding-large-vertical:         10px;
$padding-large-horizontal:       16px;

$padding-small-vertical:         5px;
$padding-small-horizontal:       10px;

$header-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
$header-height: 66px;
$header-bg: #222;
$header-color: #999;
$header-hover-bg: rgba(0, 0, 0, 0.5);

$menu-item-color: #999;

// Change font to Open Sans
$font-family-sans-serif: "Nunito", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-base: $font-family-sans-serif;
$headings-font-family: $font-family-base;

$page-header-color: $brand-highlight-color;
$headings-font-weight: normal;

$badge-font-weight: 400;
$badge-bg:             #999;
$label-default-bg:     #999;

// Form Control Widths
$input-width-mini: 60px;
$input-width-small: 90px;
$input-width-medium: 150px;
$input-width-large: 210px;
$input-width-xlarge: 270px;
$input-width-xxlarge: 530px;
$input-width-date: 160px;
$input-width-datetimelocal: 190px;
$input-width-time: 95px;

$input-color-placeholder: #999;

$validation-error-color: #b94a48;
$validation-error-bg: #ffeeee;

// Breadcrumb styling
$breadcrumb-padding-vertical:   6px;
$breadcrumb-padding-horizontal: 15px;
$breadcrumb-font-size: 12px;

// Small button
$btn-padding-y-sm: .3rem;
$btn-padding-x-sm: .6rem;
$btn-font-size-sm: $font-size-base * .9;
$btn-line-height-sm: 1.5;

// Table padding
$table-cell-padding: 8px;
$table-condensed-cell-padding: 4px 5px;

$alert-padding: 15px;

// Gradient table headers
$table-header-bg: transparent;
$table-sort-active: #307ECC;
$table-sort-hover: #547EA8;
$table-labels-grad-color1: #F8F8F8;
$table-labels-grad-color2: #ECECEC;

$nav-link-padding: 8px 12px;

$widget-header-color: #669FC7;

$footer-height: 20px;
$footer-padding-top: 25px;
$footer-padding-bottom: 10px;

$footer-height: 40px;
$footer-padding-vertical: 0px;

$formActionsBackground: inherit;

//$boxBorder:				darken($mainBackground, 15%);
$boxBorder: #D5D5D5;


$navbar-padding-vertical: 10px;
$navbar-padding-horizontal: 20px;
$navbar-height: 40px;
$page-header-bg: #fafafa;

$navbarCollapseWidth: 779px;
$navbarCollapseDesktopWidth: 780px;

//tabs & accordion
$tab-border: #C5D0DC;
$tab-active-color: #576373;
$tab-active-border: #4C8FBD;
$tab-hover-color: #4C8FBD;


$dialog-container-width: 400px;
$dialog-margin-top: 60px;
$dialog-page-header-border-color: $gray;
$dialog-page-border-width: 1px;
$dialog-page-border-radius: 4px;

// Navbar
$nav-item-bg: $brand-highlight-color;
$nav-item-color: $gray-lighter;
$navbar-mh: 58px;

$main-bg: white;

/*
 * Dropdown
 */
$dropdown-fallback-border:      transparent;
$dropdown-border:               transparent;
$dropdown-divider-bg:           '';
$dropdown-link-hover-bg:        rgba(0,0,0,0.075);
$dropdown-link-color:           #333;
$dropdown-link-hover-color:     #333;
$dropdown-link-disabled-color:  #e4e4e4;
$dropdown-divider-bg:           rgba(0,0,0,0.08);
$dropdown-link-active-color:    #333;
$dropdown-link-active-bg:       rgba(0, 0, 0, 0.075);
$zindex-dropdown:               9;
$dropdown-shadow:               0 2px 10px rgba(0, 0, 0, 0.2);

$fa-font-path: 'fonts/font-awesome-6/webfonts';

// Modals
$modal-md: 600px;

@import "variables-sidebar";
