﻿body.landing-layout {
    background-color: white;
}

$landing-responsive-cutoff: 1023px;

.landing-layout {
    .wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        width: 100%;
        display: flex;
        justify-content: flex-end;
        min-height: 500px;

        @media (max-height: 500px) {
            bottom: 500px;
        }

        @media (min-width: 1921px) {
            left: calc((100vw - 1920px) / 2);
            right: calc((100vw - 1920px) / 2);
            width: 1920px;
        }
    }

    .logo {
        position: absolute;
        top: 20px;
        right: 50px;
        float: right;
        z-index: 0;
    }

    .landing-wrapper {
        flex-grow: 1;
        align-self: center;
        text-align: center;
        height: 100%;
        background: url(images/_app/landing_background.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .landing-content {
        font-size: 24pt;
    }

    .landing-content h1 {
        font-size: 42pt;
    }

    .content {
        align-self: center;
        width: 400px;
        margin-left: 50px;
        margin-right: 50px;

        h1, h2 {
            text-align: center;
        }

        h2 {
            font-size: 1.8em;
            margin-bottom: 1em;
        }

        @media(max-width: 767px) {
            h1 {
                font-size: 1.8em;
            }

            h2 {
                font-size: 1.4em;
            }

            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .content {
        input[type=text], input[type=email], input[type=password] {
            @extend .form-control;
            display: block;
        }
    }
}

@media (max-width: $landing-responsive-cutoff) {
    .landing-layout {
        .wrapper {
            justify-content: center;
            background: url(images/_app/landing_background.jpg);
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center;
        }

        .landing-wrapper {
            display: none;
        }

        .content {

            form {
                border: 1px solid #ccc;
                background-color: white;
                padding: 20px;
            }

            width: 440px;
        }
    }
}