.starRating span {
	width: 16px;
	height: 16px;
	background-image: url("images/rating.png");
	background-position: -16px 0;
	display: inline-block;
	cursor: pointer;
}
.starRating span.chosen { background-position: 0 0; }
.starRating:hover span { background-position: -16px 0; }
.starRating:hover span.hoverChosen { background-position: 0 0; }