@use "sass:math";

// Bootstrap 3 removed the input-size classes in favour of full-width inputs.  We don't like that, bring them back!
@mixin input-width($size) {
    width: $size;
    max-width: 100%;
}

$form-label-margin-top: 7px;

// Restore the Bootstrap-2-style of horizontal forms
.control-label {
    @extend .col-sm-4;
    @extend .col-md-3;
    @extend .col-lg-2;
    font-weight: normal;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.form-group {
    @extend .row;
}

.controls {
    @extend .col-sm-8;
    @extend .col-md-9;
    @extend .col-lg-10;

    .form-check, .checkbox, .radio, .checkbox-inline {
        margin-top: $form-label-margin-top;
    }

    .checkbox-inline {
        display: inline-flex;
        align-items: center;
        margin-right: .75rem;
    }
}

.modal-content {
    .control-label {
        @extend .col-md-4;
    }

    .controls {
        @extend .col-md-8;
    }
}

.form-check {
    & > input[type=checkbox], & > input[type=radio] {
        @extend .form-check-input;
    }

    & > label {
        @extend .form-check-label;
    }

    &.form-check-inline {
        & > input[type=checkbox], & > input[type=radio] {
            // Undo .form-check-input defaults and add some `margin-right`.
            position: static;
            margin-top: 0;
            margin-right: $form-check-inline-input-margin-x;
            margin-left: 0;
        }
    }
}

.modal-lg {
    .modal-content {
        .control-label {
            @extend .col-sm-3;
        }

        .controls {
            @extend .col-sm-9;
        }
    }
}

.form-horizontal {
    .control-label {
        @include media-breakpoint-up(sm) {
            text-align: right;
            margin-top: $form-label-margin-top;
        }
    }

    .controls {
        .form-control-static {
            line-height: calc(1.5em + 0.75rem + 2px); // Same as input height
            margin-bottom: 0;
        }
    }

    .form-actions {
        @extend .col-sm-8;
        @extend .offset-sm-4;
        @extend .col-md-9;
        @extend .offset-md-3;
        @extend .col-lg-10;
        @extend .offset-lg-2;
        padding-left: 15px;
    }

    @include clearfix();
}

.form-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;

    input {
        width: auto !important;
        max-width: inherit !important;
        min-width: 10px;
    }
}

.form-horizontal, .form-inline {
    //
    // Bootstrap 3 also made us apply .form-control to all inputs.  That's a pain, so we'll try and undo that
    //
    input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"],
    input[type="email"], input[type="url"], input[type="search"]:not(.select2-search__field),
    input[type="tel"], input[type="color"], select {
        // Prevent the BS3 full-width default
        @extend .form-control;
        display: inline-block;
        @include input-width($input-width-large);

        &.input-full {
            display: block;
            max-height: none;
            width: 100%;
        }
        // TODO: Remove unused sizes and use BS4 versions
        // General classes for quick sizes
        &.input-mini {
            @include input-width($input-width-mini);
        }

        &.input-small {
            @include input-width($input-width-small);
        }

        &.input-medium {
            @include input-width($input-width-medium);
        }

        &.input-large {
            @include input-width($input-width-large);
        }

        &.input-xlarge {
            @include input-width($input-width-xlarge);
        }

        &.input-xxlarge {
            @include input-width($input-width-xxlarge);
        }
    }
    // Add useful sizes to certain types of input
    input[type=email] {
        @include input-width($input-width-large);
    }

    input[type=tel] {
        @include input-width($input-width-medium);
    }

    input[type=password] {
        @include input-width($input-width-medium);
    }

    input[type=date] {
        @include input-width($input-width-date !important);
        padding-right: 0.25rem;
    }

    input[type=datetime] {
        @include input-width($input-width-medium !important);
    }

    input[type=datetime-local] {
        @include input-width($input-width-datetimelocal !important);
        padding-right: 0.25rem;
    }

    input[type=time] {
        @include input-width($input-width-time !important);
    }

    .touch input[type=date] {
        @include input-width($input-width-medium !important);
    }

    input[type=time], input[type=number] {
        @include input-width($input-width-small);
    }

    .controls label.checkbox {
        font-weight: normal;
    }

    .checkbox {
        &.inline-fixed {
            display: inline-block;
            padding-top: 5px;
            margin-bottom: 0;
            vertical-align: middle;
            margin-right: 10px;
            @include input-width($input-width-medium);
            padding-right: 5px;
        }

        label {
            margin-bottom: 0;
        }
    }

    .input-xsmall {
        width: 30px;
    }

    .input-auto {
        width: initial;
    }

    input[type=text].cc-number {
        @include input-width(150px);
    }

    input[type=text].cc-csc {
        @include input-width(70px);
    }

    input[type=text].cc-expiry {
        @include input-width(70px);
    }

    select[multiple], select[size] {
        height: auto;
    }
}

label.radio, label.checkbox {
    font-weight: normal;
}
// Restore input-group functionality for controls not labelled as .form-control
.input-group input:not(.form-control) {
    display: table-cell;

    &:not(:first-child):not(:last-child) {
        border-radius: 0;
    }
}

.input-group input:first-child, .input-group select:first-child {
    @include border-right-radius(0);
}

.input-group input:last-child, .input-group select:last-child {
    @include border-left-radius(0);
}

.no-touch input[type=date]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

input[type=date]::-webkit-inner-spin-button {
    display: none;
    -webkit-appearance: none;
}
// Prevent textarea controls from being horizontally resized
.form-horizontal .controls textarea.input-full {
    resize: vertical;
}

.help-inline {
    @extend .text-muted;
    display: inline;
}
/*.input-append, .input-prepend {
	.btn-group {
		margin-left: -1px;
		vertical-align: top;
	}
}
.input-append {
	.btn-group:last-child .btn:last-child {
		.border-radius(0 $inputBorderRadius $inputBorderRadius 0);
	}
}*/
.table form {
    margin: 0;
}

.modal form {
    margin: 0;
}

.required label.control-label:after {
    content: "*";
    float: right;
    position: absolute;
    right: -5px;
}

.tab-content > .form-horizontal {
    @include clearfix();
}

.form-horizontal .form-inline .form-group {
    margin-left: 0;
    margin-right: 0;
}

$slider-handle: 30px;

.rangeslider,
.rangeslider__fill {
    display: block;
    -moz-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0px 1px 3px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.rangeslider {
    background: #e6e6e6;
    position: relative;
}

.rangeslider--horizontal {
    height: math.div($slider-handle, 2);
    width: 100%;
}

.rangeslider--vertical {
    width: math.div($slider-handle, 2);
    min-height: 150px;
    max-height: 100%;
}

.rangeslider--disabled {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
    opacity: 0.4;
}

.rangeslider__fill {
    background: #00ff00;
    position: absolute;
}

.rangeslider--horizontal .rangeslider__fill {
    top: 0;
    height: 100%;
}

.rangeslider--vertical .rangeslider__fill {
    bottom: 0;
    width: 100%;
}

.rangeslider__handle {
    background: white;
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    width: $slider-handle;
    height: $slider-handle;
    position: absolute;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2ZmZmZmZiIgc3RvcC1vcGFjaXR5PSIwLjAiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xIi8+PC9saW5lYXJHcmFkaWVudD48L2RlZnM+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgZmlsbD0idXJsKCNncmFkKSIgLz48L3N2Zz4g');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(255, 255, 255, 0)), color-stop(100%, rgba(0, 0, 0, 0.1)));
    background-image: -moz-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.1));
    -moz-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.rangeslider__handle:after {
    content: "";
    display: block;
    width: math.div($slider-handle, 2) - 2;
    height: math.div($slider-handle, 2) - 2;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEzIi8+PHN0b3Agb2Zmc2V0PSIxMDAlIiBzdG9wLWNvbG9yPSIjZmZmZmZmIiBzdG9wLW9wYWNpdHk9IjAuMCIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.13)), color-stop(100%, rgba(255, 255, 255, 0)));
    background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
    background-image: linear-gradient(rgba(0, 0, 0, 0.13), rgba(255, 255, 255, 0));
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}

.rangeslider__handle:active {
    background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4gPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJncmFkIiBncmFkaWVudFVuaXRzPSJvYmplY3RCb3VuZGluZ0JveCIgeDE9IjAuNSIgeTE9IjAuMCIgeDI9IjAuNSIgeTI9IjEuMCI+PHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwLjEiLz48c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC4xMiIvPjwvbGluZWFyR3JhZGllbnQ+PC9kZWZzPjxyZWN0IHg9IjAiIHk9IjAiIHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbGw9InVybCgjZ3JhZCkiIC8+PC9zdmc+IA==');
    background-size: 100%;
    background-image: -webkit-gradient(linear, 50% 0%, 50% 100%, color-stop(0%, rgba(0, 0, 0, 0.1)), color-stop(100%, rgba(0, 0, 0, 0.12)));
    background-image: -moz-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
    background-image: -webkit-linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
    background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.12));
}

.rangeslider--horizontal .rangeslider__handle {
    top: math.div($slider-handle, -4);
    touch-action: pan-y;
    -ms-touch-action: pan-y;
}

.rangeslider--vertical .rangeslider__handle {
    left: math.div($slider-handle, -4);
    touch-action: pan-x;
    -ms-touch-action: pan-x;
}

input[type="range"]:focus + .rangeslider .rangeslider__handle {
    -moz-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
    -webkit-box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
    box-shadow: 0 0 8px rgba(255, 0, 255, 0.9);
}

label {
    font-weight: initial;
}

input {
    //    font-family: 'Font Awesome 6 Pro', $font-family-base;
}

.form-inline .form-group {
    margin-left: 0;
    @extend .mr-sm-2;

    label {
        @extend .mr-sm-2;
    }
}

option:disabled {
    color: lightgray;
}

.radio-list-group {
    & > .list-group-item {
        user-select: none;
    }

    & > input[type="radio"] {
        display: none;
    }

    & > input[type="radio"] + .list-group-item {
        cursor: pointer;
        margin-bottom: 0;
    }

    & > input[type="radio"]:checked + .list-group-item {
        @extend .active;
    }
}