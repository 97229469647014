.fileinput-button {
    position: relative;
    overflow: hidden;
}

.fileinput-button input[type=file] {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    border: solid transparent;
    border-width: 0 0 100px 200px;
    opacity: 0;
    filter: alpha(opacity=0);
    -moz-transform: translate(-300px, 0) scale(4);
    direction: ltr;
    cursor: pointer;
}

.fileupload-buttonbar .btn,
.fileupload-buttonbar .toggle {
    margin-bottom: 5px;
}

input[type=file] {
    @include input-width($input-width-xlarge);
}

.attachment-item {
    display: inline-block;
    border: solid 1px #DDD;
    background-color: #F0F0F0;
    line-height: 18px;
    font-size: 0.9em;
    padding: 2px 2px 2px 4px;
    margin: 0 8px 4px 0;
}

.attachment-item .thumbnail {
    text-align: center;
    margin: 0 auto;
}

.attachedfile-list {
    li.media {
        padding: 0.25rem;
        position: relative;

        &:hover {
            background-color: #eee;
        }

        .thumbnail {
            width: 100px;
            margin-right: 1rem;
            text-align: center;
        }

        i.thumbnail {
            line-height: 50px;
            font-size: 3em;
        }

        .btn-toolbar {
            position: absolute;
            right: 0.25rem;
            top: 0;
            bottom: 0;
            z-index: 2;
            display: flex;
            align-items: center;
        }

        .attachment-info {
            & > span {
                margin-right: 2rem;
            }

            @extend .text-muted;
            font-size: 0.8em;
        }
    }

    margin-bottom: 0;
}