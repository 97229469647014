@import "tickets";

@media print {

    #header { 
        display: none;
    }

    .navbar {
        display: none;
    }

    .sidebar {
        display: none;
    }

    // Don't show breadcrumbs...
    #breadcrumbs {
        display: none;
    }

    // Remove outline of content
    #main-content {
        -webkit-box-shadow: none;
        box-shadow: none;
        border: none;
    }
    .main-content {
        margin-left: 0 !important;
    }

    // Always expand ticket entries
    .ticket-entry {
        .preview {
            display: none;
        }
    
        .ticket-entry-content {
            display: block;
        }
    
        .date-brief {
            display: none;
        }
    
        .date-full {
            display: inline;
        }
    }
}
