@mixin pull-right() {
    @include float-right;
}
.pull-right {
    @extend .float-right;
}
@mixin pull-left() {
    @include float-left;
}
.pull-left {
    @extend .float-left;
}


@mixin border-radius-individual($topright: 0, $bottomright: 0, $bottomleft: 0, $topleft: 0) {
	border-radius: $topleft, $topright, $bottomright, $bottomleft;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box;
}

//animation
@mixin animation($params) {
	-moz-animation: $params;
	-webkit-animation: $params;
	-o-animation: $params;
	-ms-animation: $params;
	animation: $params;
}

@mixin animation-duration($duration: 1s) {
	-moz-animation-duration: $duration;
	-webkit-animation-duration: $duration;
	-o-animation-duration: $duration;
	-ms-animation-duration: $duration;
	animation-duration: $duration;
}

@mixin responsive-visibility-custom($mode) {
	display: $mode !important;
}

@mixin responsive-visibility-inline() {
	@include responsive-visibility-custom(inline);
}

@mixin responsive-visibility-inline-block() {
	@include responsive-visibility-custom(inline-block);
}

@mixin animated($name, $duration) {
    -webkit-animation-name: $name;
    animation-name: $name;
    -webkit-animation-duration: $duration;
    animation-duration: $duration;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

@mixin scale-rotate($scale, $rotate) {
    -webkit-transform: scale($scale) rotate($rotate);
    -ms-transform: scale($scale) rotate($rotate);
    -o-transform: scale($scale) rotate($rotate);
    transform: scale($scale) rotate($rotate);
}

@mixin user-select ($val) {
    -webkit-touch-callout:  $val;
    -webkit-user-select:    $val;
    -khtml-user-select:     $val;
    -moz-user-select:       $val;
    -ms-user-select:        $val;
    user-select:            $val;
}

@mixin bg-cover($image) {
    background-image: url($image);
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

@mixin bg-cover-inline() {
    background-repeat: no-repeat;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-position: center;
}

@mixin hover-pop($background: rgba(0,0,0,0.5), $radius: 0, $duration: 250ms, $zindex: 0) {
    @include media-breakpoint-up(sm) {
        position: relative;
        
        &:before {
            left: 0;
            top: 0;
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            transform: scale3d(0,0,0);
            @include transition(all $duration);
            backface-visibility: hidden;
            background-color: $background; 
            z-index: $zindex;
            border-radius: $radius;
            opacity: 0;
        }
        
        &:hover:before,
        &.open:before {
            transform: scale3d(1,1,1);
            opacity: 1;
        }
    }
}

@mixin row-variant($state, $background, $border: null) {
    // Exact selectors below required to override `.table-striped` and prevent
    // inheritance to nested tables.
    .#{$state} {
        $color: color-contrast(opaque($body-bg, $background));

        &,
        > th,
        > td {
            background-color: $background;
        }

        @if $border != null {
            th,
            td,
            thead th,
            tbody + tbody {
                border-color: $border;
            }
        }
    }
    // Hover states for `.table-hover`
    // Note: this is not available for cells or rows within `thead` or `tfoot`.
    .table-hover {
        $hover-background: darken($background, 5%);

        .#{$state} {
            @include hover() {
                background-color: $hover-background;

                > td,
                > th {
                    background-color: $hover-background;
                }
            }
        }
    }
}

// TODO: Remove after BS5
@mixin bs5-table-variant($state, $background, $border: null) {
  .table-#{$state} {
    $color: color-contrast(opaque($body-bg, $background));

    &,
    > th,
    > td {
      background-color: $background;
    }

    @if $border != null {
      th,
      td,
      thead th,
      tbody + tbody {
        border-color: $border;
      }
    }
  }
}
// scss-docs-end table-variant