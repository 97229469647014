/// <reference path="../../bootstrap/scss/_functions.scss" />
/// <reference path="../../bootstrap/scss/_variables.scss" />
/// <reference path="variables-custom.scss" />

.page-header {
	padding-bottom: 0;
	margin: 0 0 20px 0;
	border-bottom: 1px dotted $gray-lighter;
	color: $page-header-color;

	h1 {
		margin-top: 20px;
		font-weight: lighter;
		font-size: 28px;
	}
}

.well {
	@extend .bg-light;
	@extend .card;
	@extend .card-body;
	@extend .mb-3;
}

.with-section-nav {
	.page-header {
		border-bottom: none;
		background-color: $page-header-bg;
		//background-color: blue;
		margin: 0px -15px -1px -16px;
		padding: 10px 15px 10px 16px;

		@include media-breakpoint-down(xs) {
			margin-top: -10px;
			margin-bottom: 0;
			margin-left: -1px;
			margin-right: -1px;
			padding: 10px 0 10px 1px;
		}
	}

	.section-nav {
		background-color: $page-header-bg;
	}
}

/*h1, .h1 {
	font-size: floor($font-size-base * 2.0);
}
h2, .h2 {
	font-size: floor($font-size-base * 1.8);
}
h3, .h3 {
	font-size: ceil($font-size-base * 1.6);
}
h4, .h4 {
	font-size: ceil($font-size-base * 1.4);
}
h5, .h5 {
	font-size: $font-size-base;
}
h6, .h6 {
	font-size: ceil($font-size-base * 0.85);
}*/

h1 small, .h1 small {
	font-size: ceil($font-size-base * 1.50);
	display: block;
	margin-top: 10px;
}
.subtitle h2 {
    margin: 5px 0 20px 0;
    //border-bottom: 1px dotted $gray-lighter;
    color: $page-header-color;
    font-weight: lighter;
}
// ~24px
h2 small, .h2 small {
	font-size: ceil($font-size-base * 1.10);
}
// ~18px
h3 small, .h3 small,
h4 small, .h4 small {
	font-size: $font-size-base;
}

.truncate {
	white-space: nowrap;
	-ms-text-overflow: ellipsis;
	-o-text-overflow: ellipsis;
	text-overflow: ellipsis;
	overflow: hidden;
}

address {
	white-space: pre-line;
}

dl.with-labels {
    dd, dt {
        line-height: $line-height-base * 1.2;
    }
}
div.with-labels {
    line-height: $line-height-base * 1.2;
}
.table > tbody > tr > td.with-labels {
    line-height: $line-height-base * 1.2;
}

dl.dl-horizontal {

	dt {
		float: left;
		width: 160px;
		text-align: right;
		//line-height: 2;
		//min-height: 1.8rem;
	}

	dd {
		margin-left: 180px;
		//line-height: 2;
		//min-height: 1.8rem;
	}

	&.narrow {
		dt {
			width: 100px;
			@include text-truncate();
		}

		dd {
			margin-left: 120px;
		}
	}

	&.very-narrow {
		dt {
			width: 60px;
			@include text-truncate();
		}

		dd {
			margin-left: 80px;
			@include text-truncate();
		}
	}
}
// TODO: Remove incidences of info-list class (unnecessary in BS4)
dl.info-list {
    dd {
		margin-bottom: 7px;
//        margin-bottom: ceil($line-height-computed * 0.3333);
    }

    margin: 0;
}

dl.dl-inline {

	dt {
		margin-left: 10px;
		display: inline-block;
		margin-right: 10px;
	}

	dd {
		display: inline-block;
		margin-right: 30px;
	}

	margin-bottom: 0;
}

.form-group ul:not(.dropdown-menu), .info-list dd ul:not(.dropdown-menu) {
    padding-left: 20px;
}

.power-off, .power-off[disabled] {
	color: red !important;
}

.power-on, .power-on[disabled] {
	color: green !important;
}

.text-deleted {
    text-decoration: line-through;
}