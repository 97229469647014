/// <reference path="../../jspm:bootstrap-less/bootstrap/variables" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/mixins" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/buttons" />

// Customisations of Bootstrap's existing stuff
@import "icons";
@import "buttons-custom";
@import "tables-custom";
@import "progress-bars-custom";
@import "validation";

// Additional components - external
@import "typeahead.js-bootstrap";
@import "alerts-custom";
@import "gallery";
//@import "../../../node_modules/select2-bootstrap-theme/dist/select2-bootstrap";
@import "select2/dist/css/select2";
@import "@ttskch/select2-bootstrap4-theme/dist/select2-bootstrap4";
@import "nv.d3";
@import "nvd3-custom";
@import "ko.plus/dist/ko.plus";
@import "toastr/toastr";
@import "perfect-scrollbar/css/perfect-scrollbar";

// Theme components
@import "header";

// Additional components - Mine
@import "animations";
@import "sidebar";
@import "dialog-page";
@import "tabs";
@import "tickets";
@import "accounthistory";
@import "starrating";
@import "attachments";
@import "print";
@import "graphs";
@import "services";
@import "panels";
@import "footer";
@import "lists";
@import "access";
@import "wizard";
@import "monitoring";
@import "racks";

html, body {
    min-height: 100vh;
}

body {
    position: relative;
}

.main-content {
    display: block;
    background-color: $main-bg;

    @include media-breakpoint-up(sm) {
        border: solid 1px #ccc;
        border-right: none;
        border-bottom-left-radius: 4px;
    }
}

.body-content {
    min-height: 400px;
    padding: 0 15px 20px;
    width: 100%;
    display: inline-block;
}

.table-header {
    margin-bottom: 4px;

    .btn {
        @extend .btn-sm;
    }
}

textarea.autosize-transition {
    -moz-transition: height 0.1s;
    -o-transition: height 0.1s;
    -webkit-transition: height 0.1s;
    transition: height 0.1s;
}

.tab-content {
    .row {
        margin-left: 0;
        margin-right: 0;
    }
}

@media (min-width: 768px) {
    .modal-lg {
        max-width: 900px;
        width: auto;
    }
}

@include media-breakpoint-down(xs) {
    .body-content {
        padding-left: 1px;
        padding-right: 1px;
    }
}

.modal-full .modal-dialog {
    width: 100%;
}

.modal-body.modal-scroll {
    overflow-y: auto;
    max-height: calc(100vh - 200px);
}

fieldset > legend {
    padding: 10px 0; // Extra space allows us to pull-right buttons
}

a:focus, a:active, .btn:focus, .btn:active, .btn:focus:active, .btn.active:focus, .btn.focus {
    outline-style: none;
    outline: none;
    text-decoration: none;
}

.btn-toolbar > .pull-right {
    & > .btn-group, & > .btn, & > .input-group {
        margin-left: 5px;
    }
}

.label {
    font-weight: normal;
    font-size: 85%;

    @extend .badge;
}

.label-default {
    @extend .badge-secondary;
}

.label-warning {
    @extend .badge-warning;
}

.label-info {
    @extend .badge-info;
}

.label-success {
    @extend .badge-success;
}

.label-danger {
    @extend .badge-danger;
}

.page-operations {
    /*.input-group {
        display: inline-table;
        vertical-align: middle;

        .input-group-addon,
        .input-group-btn,
        .form-control {
            width: auto;
        }
    }*/
    @extend .float-right;
    @extend .btn-toolbar;
}

.btn-toolbar, table-header-form {
    .input-group-addon,
    .input-group-btn,
    .form-control {
        width: auto;
    }
}

.page-header {
    @include clearfix();
    padding-top: 20px;

    h1 {
        margin-top: 0;
        @extend .pull-left;
    }
}

.subtitle {
    @include clearfix();

    h2 {
        @extend .pull-left;
    }
}

.copy-text:hover {
    cursor: pointer;
}

.popover {
    max-width: 450px;
}

.expand-icon {
    @extend .fa-fw;
    @extend .fal;
    @extend .fa-minus-square;

    &.collapsed {
        @extend .fa-plus-square;
    }
}

// TODO: Remove all hidden-xs, hidden-sm, visible-xs... etc

// Transition classes from BS3 to BS4
.hidden-xs {
    @extend .d-none;
    @extend .d-sm-block;
}

tr.hidden-xs {
    @extend .d-sm-table-row;
}

th.hidden-xs, td.hidden-xs {
    @extend .d-sm-table-cell;
}

span.hidden-xs {
    @extend .d-sm-inline;
}

.visible-xs {
    @extend .d-sm-none;
}

// TODO: Replace col-xs-6 by col-6 etc
@for $i from 1 through 11 {
    .col-xs-#{$i} {
        @extend .col-#{$i};
    }
    .col-xs-offset-#{$i} {
        @extend .offset-#{$i};
    }
}

// TODO: Replace old modal markup style with BS4
.modal-header > .close {
    order: 1;
}

// TODO: Replace old dropdown ul,li markup with BS4 version using A & Div
ul.dropdown-menu > li > a {
    @extend .dropdown-item;
}
ul.dropdown-menu > li.divider {
    @extend .dropdown-divider;
}

.badge-default {
    @extend .badge-secondary;
}

.badge-note {
    @include badge-variant(#FFFF99);
}

.unsupported-browser {
    display: none;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: block;
    }
}