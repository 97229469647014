@use "sass:math";

$rack-legend-width: 30px;
$rack-legend-spacing: 6px;
$rack-width: 230px;
$rack-legend-font-size: 10px;
$rack-unit-font-size: 13px;
$rack-unit-height: 20px;

div.rack-header {
    margin-left: $rack-legend-width;
    text-align: center;
    width: $rack-width;
}

ul.rack-legend {
    float: left;
    list-style-type: none;
    margin-right: $rack-legend-spacing;
    padding: 0;
    width: $rack-legend-width;

    li {
        display: block;
        font-size: $rack-legend-font-size;
        height: $rack-unit-height;
        overflow: hidden;
        padding: 5px 0;
        text-align: right;
    }
}

div.rack-frame {
    float: left;
    position: relative;
}

ul.rack {
    border: 2px solid #404040;
    float: left;
    list-style-type: none;
    padding: 0;
    position: absolute;
    width: $rack-width;

    &.far-face {
        background-color: #f7f7f7;
        z-index: 100;

        li.occupied {
            background: repeating-linear-gradient( 45deg, #f7f7f7, #f7f7f7 7px, #f0f0f0 7px, #f0f0f0 14px );
        }

        li.blocked {
            background: repeating-linear-gradient( 45deg, #f7f7f7, #f7f7f7 7px, #ffc7c7 7px, #ffc7c7 14px );
        }

        li.reserved {
            background: repeating-linear-gradient( 45deg, #f7f7f7, #f7f7f7 7px, #c7c7ff 7px, #c7c7ff 14px );
        }
    }

    &.near-face {
        z-index: 200;

        li.occupied {
            border-top: 1px solid #474747;
            color: #474747;
        }

        li.device-side {
            background-color: #888;
        }

        li:first-child {
            border-top: 0;
        }

        li.available > a, li.available > div {
            color: #0000ff;
            display: none;
            text-decoration: none;
        }

        li.available:hover {
            background-color: #ffffff;

            & > div {
                color: #888;
            }

            & > a, & > div {
                display: block;
                flex-grow: 1;
            }
        }
    }

    li {
        border-top: 1px solid #e0e0e0;
        display: flex;
        flex-direction: row;
        font-size: $rack-unit-font-size;
        height: $rack-unit-height;
        overflow: hidden;
        text-align: center;

        & > a {
            color: #ffffff;
            display: flex;
            flex-grow: 1;
            align-items: center;

            & > i {
                flex-grow: 0;
                margin: 0 2px;
            }
        }

        & > a:hover {
            text-decoration: none;
        }

        span {
            flex-grow: 1;
        }

        span.device-name {
            font-size: 12px;
        }
    }

    li.occupied + li.available {
        border-top: 1px solid #474747;
    }
}

@for $height from 1 to 50 {
    ul.rack li.h#{$height}u {
        height: $height * $rack-unit-height;

        a, span {
            padding: math.div((($height - 1) * $rack-unit-height), 2) 0;
        }
    }
}

.ports-diagram {
    ul {
        list-style-type: none;
        margin: 0 auto;
        padding: 0;
        display: flex;
        justify-items: center;
        align-content: center;

        &.DownRight {
            flex-wrap: wrap;
            flex-direction: column;
        }

        &.UpRight {
            flex-wrap: wrap;
            flex-direction: column-reverse;
        }

        &.RightDown {
            flex-wrap: wrap;
        }

        &.RightUp {
            flex-wrap: wrap-reverse;
        }
    }

    li {
        background-color: #ccc;
        font-size: $rack-legend-font-size;
        text-align: center;
        border: solid 1px #888;
        position: relative;

        &.has-connection {
            background-color: aliceblue;
        }

        &.blocked {
            background-color: theme-color-level("warning", $alert-bg-level) !important;
        }

        &.empty {
            background-color: #444;
            border-color: #888;
            color: #888;
        }

        & > i.port-logo {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            font-size: 1.75em;
        }

        & > i.port-status {
            position: absolute;
            top: 2px;
            right: 2px;
            font-size: 0.75em;

            &.port-up {
                color: green;
            }

            &.port-disabled {
                color: orange;
            }

            &.port-down {
                color: grey;
            }
        }

        & > i.asset-status {
            position: absolute;
            bottom: 2px;
            left: 2px;
            font-size: 0.75em;
        }

        & > i.device-type {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            line-height: 100%;
            font-size: 1.25em;
        }

        &.has-connection {
            > i.port-status {
                &.port-down {
                    color: red;
                }
            }
        }

        & > .bay-name {
            position: absolute;
            top: 2px;
            left: 2px;
            line-height: 1;
        }

        & > a {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            color: inherit;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.5em;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

$xc-port-width: 120px;
$xc-port-spacing: 10px;
$xc-terminations-margin: 20px;

.cable-trace {
    display: flex;
    flex-direction: column;

    .device-wrapper {
        display: flex;
        flex-direction: column;
    }

    .connections {
        min-height: 50px;
        margin-left: $xc-terminations-margin;
        margin-right: $xc-terminations-margin;
        display: flex;
        flex-direction: row;

        .cable {
            padding: 10px;
        }

        .cable-wrapper {
            width: $xc-port-width;
            margin-right: $xc-port-spacing;
        }

        .connection-separator {
            background-color: white;
            color: #eee;
            height: 20px;
            line-height: 20px;
            margin-top: -10px;
            z-index: 10;
        }
    }

    .device {
        border: solid 1px #ccc;
        padding: 10px;
        z-index: 1;
        position: relative;
        background-color: #eee;

        &.highlighted {
            //box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
            //background-color: palegoldenrod;
            box-shadow: yellow 0px 0px 15px;
        }
    }

    .terminations {
        margin: -5px $xc-terminations-margin;
        z-index: 2;
        display: flex;
        flex-direction: row;

        .port {
            padding: 5px;
            border: solid 1px #ccc;
            background: white;
            margin-right: $xc-port-spacing;
            width: $xc-port-width;
            position: relative;

            &.highlighted {
                box-shadow: yellow 0px 0px 15px;
            }
        }

        &.terminations-before, &.terminations-after {
            .port {
                &:not(:first-child)::after {
                    content: "";
                    border-right: solid 3px #ccc;
                    display: block;
                    position: absolute;
                    width: 130px;
                    left: -120px;
                    height: 10px;
                    font-size: 1px;
                }
            }
        }

        &.terminations-before .port:not(:first-child)::after {
            top: -10px;
            border-top: solid 3px #ccc;
        }

        &.terminations-after .port:not(:first-child)::after {
            bottom: -10px;
            border-bottom: solid 3px #ccc;
        }

        .port-spacer {
            padding: 5px;
            margin-right: $xc-port-spacing;
            width: $xc-port-width;
        }
    }

    .termination {
        padding: 5px;
        margin: -5px 20px;
        border: solid 1px #ccc;
        background: white;
        z-index: 2;

        &.highlighted {
            box-shadow: yellow 0px 0px 15px;
        }
    }

    .cable {
        padding: 20px;
        margin-left: 30px;
        border-left: solid 3px #ccc;

        &.highlighted {
            border-left-color: palegoldenrod;
        }

        .alert {
            margin-bottom: 0;
            font-size: 0.9em;
            padding: 0.5rem 1rem;
        }

        &.no-connection {
            border-left: dotted 3px #eee;
            min-height: 40px;
        }

        &.not-present {
            border-left: none;
            min-height: 40px;
        }
    }
}