.result-section {
	@include clearfix();
	border-bottom: solid 1px #eee;
	margin-bottom: 10px;

	&:empty {
		display: none;
	}
}

.visitor-id-card {
	border: solid 1px #CCC;
	border-radius: 2px;
	float: left;
	width: 300px;
	height: 142px;
	margin-right: 10px;
	margin-bottom: 10px;
	padding: 10px;
	position: relative;

	&.visitor-id-card-small {
		width: 250px;
		height: 120px;

		.photo {
			width: 77px;
			height: 102px;
			line-height: 120px;

			img {
				height: 100px;
				width: 75px;
			}
		}
	}

	& > a {
		margin: -10px;
		padding: 10px;
		display: block;
		width: 300px;
		height: 140px;
	}

	a {
		color: $body-color;

		&:hover {
			background-color: #eee;
			text-decoration: none;
		}
	}

	.photo {
		float: left;
		border: solid 1px black;
		background-color: #aaa;
		width: 92px;
		height: 122px;
		line-height: 140px;
		text-align: center;
		position: relative;

		& img {
			width: 90px;
			height: 120px;
			position: absolute;
			top: 0;
			left: 0;
		}

		& > i {
			color: #444;
			font-size: 4em;
		}
	}

	.details {
		margin-left: 100px;
		height: 100%;
		position: relative;

		.name {
			font-size: 1.2em;
		}

		.location {
			font-size: 0.8em;
		}

		.date {
			font-size: 0.8em;
		}

		.status {
			font-size: 0.8em;
		}

		.flags {
			@include clearfix();
			text-align: right;
			position: absolute;
			bottom: 0;
			right: 0;
		}
	}

	.commands {
		@include clearfix();
		text-align: right;
		position: absolute;
		bottom: 10px;
		right: 10px;
	}
}

.licence-plate {
	line-height: 1.8em;
	background-color: lightyellow;
	border: solid 1px #888;
	font-weight: bold;
	border-radius: 3px;
	padding: 2px 5px;
}
