.pulsate {
	-webkit-animation: pulsate 2s ease-in-out;
	-moz-animation: pulsate 2s ease-in-out;
	-o-animation: pulsate 2s ease-in-out;
	animation: pulsate 2s ease-in-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

.pulsate-1 {
	-webkit-animation: pulsate 1s ease-in-out;
	-moz-animation: pulsate 1s ease-in-out;
	-o-animation: pulsate 1s ease-in-out;
	animation: pulsate 1s ease-in-out;
	-webkit-animation-iteration-count: infinite;
	-moz-animation-iteration-count: infinite;
	-o-animation-iteration-count: infinite;
	animation-iteration-count: infinite;
}

@-webkit-keyframes pulsate {
	0% {
		opacity: 0.2;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.2;
	}
}