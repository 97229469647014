.alert {
    display: flex;
    align-items: flex-start;

    > span {
        flex-grow: 1;
    }

    .btn-toolbar {
        flex-grow: 0;
    }
}

.alert-pending {
    @extend .alert-info;
    //@include alert-variant($alert-info-bg, $alert-info-border, $alert-info-text);
}

.alert-info:before, .alert-success:before, .alert-security:before, .alert-warning:before, .alert-danger:before,
.alert-error:before {
    @extend %fa-icon;
    @extend .fas;
    @extend .fa-fw;
    padding-top: 0.2em;
    margin-right: 0.5em;
}

.alert-info:before {
    content: fa-content($fa-var-info);
}

.alert-success:before {
    content: fa-content($fa-var-check-circle);
}

.alert-warning:before {
    content: fa-content($fa-var-exclamation-triangle);
}

.alert-error:before {
    content: fa-content($fa-var-exclamation-circle);
}

.alert-danger:before {
    content: fa-content($fa-var-exclamation-circle);
}

.alert-security:before {
    content: fa-content($fa-var-lock);
}

.alert-pending:before {
    padding-top: 0.2em;
    margin-right: 0.5em;
    content: '';
    @extend .spinner-border;
    @extend .spinner-border-sm;
}

.alert h4 {
    display: inline-block;
}

//
// Buttons placed inside alerts should automatically be small so they fit
//
.alert .btn {
    @extend .btn-sm;
    margin-top: -3px;
    font-size: 12px;
}
