.wizard {
	margin: 20px auto;
	background: #fff;
}

.wizard .nav-tabs {
	position: relative;
	margin: 40px auto;
	margin-bottom: 0;
	border-bottom-color: #e0e0e0;
	border-bottom: none;

	& > li .wizard-label {
		position: absolute;
		transform: translateY(-35px);
		width: 100px;
		margin: 0px auto;

		@media(min-width:768px) {
			display: inherit;

			&.mobile {
				display: none;
			}
		}

		@media(max-width:767px) {
			display: none;

			&.mobile {
				display: inherit;
				width: 40px;
				text-align: center;
			}
		}
	}

	& > li.disabled {
		color: #999;

		& > a:hover {
			color: #999;
			cursor: default;
		}
	}

	& > li.active {
		color: #5bc0de;

		& > a:hover {
			color: #5bc0de;
		}
	}

	& > li.success {
		color: #5cb85c;

		& > a:hover {
			color: #5cb85c;
		}
	}

	& > li.warning {
		color: #f0ad4e;

		& > a:hover {
			color: #f0ad4e;
		}
	}

	& > li.danger {
		color: #d9534f;

		& > a:hover {
			color: #d9534f;
		}
	}

	& > li > a {
		border: none;
	}

	& > li.active > a {
		box-shadow: none;
	}

	& > li > a, & > li > a:focus {
		background-color: transparent;
	}

	& > li.active > a, & > li.active > a:hover, & > li.active > a:focus {
		margin-top: 0px;
	}
}

.wizard > div.wizard-inner {
	position: relative;
}

.connecting-line {
	height: 2px;
	background: #e0e0e0;
	position: absolute;
	width: 80%;
	margin: 0 auto;
	left: 0;
	right: 0;
	top: 50%;
	z-index: 1;
}

.wizard .nav-tabs > li.active > a {
	color: #555555;
	cursor: default;
	border: 0;
	border-bottom-color: transparent;
}

.wizard .nav-tabs > li.success > a {
	color: #5cb85c;
}

.wizard .nav-tabs > li.warning > a {
	color: #f0ad4e;
}

.wizard .nav-tabs > li.danger > a {
	color: #d9534f;
}

span.round-tab {
	width: 70px;
	height: 70px;
	line-height: 70px;
	display: inline-block;
	border-radius: 100px;
	background: #fff;
	border: 2px solid #e0e0e0;
	z-index: 2;
	position: absolute;
	left: 0;
	text-align: center;
	font-size: 25px;
}

span.round-tab i {
	color: #555555;
}

.wizard li.active span.round-tab {
	background: #fff;
	border: 2px solid #5bc0de;
}

.wizard li.success span.round-tab {
	border: 2px solid #5cb85c;
}

.wizard li.warning span.round-tab {
	border: 2px solid #f0ad4e;
}

.wizard li.danger span.round-tab {
	border: 2px solid #d9534f;
}

.wizard li.active span.round-tab i {
	color: #5bc0de;
}

.wizard li.success span.round-tab i {
	color: #5cb85c;
}

.wizard li.warning span.round-tab i {
	color: #f0ad4e;
}

.wizard li.danger span.round-tab i {
	color: #d9534f;
}

.wizard .nav-tabs > li {
	width: 25%;
}

.wizard li:after {
	content: " ";
	position: absolute;
	left: 47%;
	opacity: 0;
	margin: 0 auto;
	bottom: 0px;
	border: 5px solid transparent;
	border-bottom-color: #5bc0de;
	transition: 0.1s ease-in-out;
	transform: translateY(15px);
}

.wizard li.active:after {
	content: " ";
	position: absolute;
	left: 47%;
	opacity: 1;
	margin: 0 auto;
	bottom: 0px;
	border: 10px solid transparent;
	border-bottom-color: #5bc0de;
	transform: translateY(15px);
}

.wizard li.success:after {
	border-bottom-color: #5cb85c;
}

.wizard li.warning:after {
	border-bottom-color: #f0ad4e;
}

.wizard li.danger:after {
	border-bottom-color: #d9534f;
}

.wizard .nav-tabs > li a {
	width: 70px;
	height: 70px;
	margin: 0px auto;
	border-radius: 100%;
	padding: 0;
}


.wizard .tab-pane {
	position: relative;
	padding-top: 50px;
}

.wizard h3 {
	margin-top: 0;
}

@media( max-width : 585px ) {

	.wizard {
		width: 90%;
		height: auto !important;
	}

	span.round-tab {
		font-size: 16px;
		width: 50px;
		height: 50px;
		line-height: 50px;
	}

	.wizard .nav-tabs > li a {
		width: 50px;
		height: 50px;
		line-height: 50px;
	}

	.wizard li.active:after {
		content: " ";
		position: absolute;
		left: 35%;
	}
}
