/// <reference path="../../jspm:bootstrap-less/bootstrap/variables.less" />
/// <reference path="../../jspm:bootstrap-less/bootstrap/mixins.less" />
/// <reference path="variables-custom.less" />

.tab-content {
    border: 1px solid $tab-border;
    border-top: none;
    padding: 16px 12px;
    //z-index: 11;
    @media only screen and (max-width: 480px) {
        border-left: none;
        border-right: none;
        padding: 16px 0px;
    }

    &.nav-tab-content {
        border: none;
        padding-left: 0;
        padding-right: 0;
    }
}

.nav-tabs {
    border-color: #C5D0DC;
    align-items: flex-end;

    .nav-item:first-child .nav-link {
        margin-left: 0;
    }

    .nav-link {
        padding: 0.5rem 0.8rem;
        margin-left: -1px;

        &, &:focus {
            border-radius: 0 !important;
            background-color: #F9F9F9;
            color: #999;
            position: relative;
            border-color: $tab-border;
        }

        &:hover {
            background-color: #FFF;
            color: $tab-hover-color;
            border-color: $tab-border;
        }

        &:active, &:focus {
            outline: none !important;
        }

        &.active {
            &, &:hover, &:focus {
                color: $tab-active-color;
                border-color: $tab-border;
                border-top: 2px solid $tab-active-border;
                border-bottom: 2px solid transparent;
                background-color: #FFF;
                margin-top: -1px;
                box-shadow: 0 -2px 3px 0 rgba(0,0,0,0.15);
            }
        }
    }

    > li {
        &.tab-title {
            @extend h5;
            flex-grow: 1;
            padding-bottom: 0.5rem;
            padding-right: 0.8rem;
            margin-bottom: 0;
        }

        &.tab-toolbar {
            flex-grow: 1;
            text-align: right;
            @extend .pb-2;

            .form-control {
                display: inherit;
                width: inherit;
            }
        }
        /* > a {
            margin-left: -1px;

            @extend .nav-link;
        }
        //a
        &:first-child > a {
            margin-left: 0;
        }

        &.active > a {
        }*/
    }

    > li > a {
        /* icon and badges */
        > .badge {
            // padding: 0 4px;
            // line-height: 13px;
            // font-size: 0.9em;
            font-weight: normal;
            opacity: 0.7;
        }
    }

    > li.active > a {
        > .badge, > [class*="icon-"] {
            opacity: 1;
        }
    }

    li [class*=" icon-"], li [class^="icon-"] {
        width: 1.25em;
        display: inline-block;
        text-align: center;
    }

    > li.open .dropdown-toggle {
        /* dropdown in tabs */
        background-color: #4F99C6;
        border-color: #4F99C6;
        color: #FFF;

        > [class*="icon-"] {
            color: #FFF !important;
        }

        .caret {
            margin-top: 7px;
        }
    }

    &.section-nav {
        margin-left: -16px;
        margin-right: -15px;
        padding-left: 15px;
        padding-right: 15px;
        //background-color: #fafafa;
        @include media-breakpoint-down(xs) {
            margin-left: -1px;
            margin-right: -1px;
            padding-left: 2px;
            padding-right: 2px;
        }
    }
}

.nav-pills .open .dropdown-toggle, .nav > li.dropdown.open.active > a:hover, .nav > li.dropdown.open.active > a:focus {
    background-color: #4F99C6;
    border-color: #4F99C6;
    color: #FFFFFF;
}

/* bigger tab buttons */
.nav-tabs > li:not(.active):not(.open) > a:not(:hover) > [class*="icon-"]:first-child.disabled {
    color: #909090 !important;
}

/* bigger tab buttons */
.nav-tabs.tab-size-bigger > li > a {
    padding-left: 14px;
    padding-right: 14px;

    > [class*="icon-"]:first-child {
        display: block;
        margin-bottom: 6px;
        width: auto;
    }
}

//some tab customizations

/* blue tabs*/
.nav-tabs.background-blue {
    padding-top: 6px;
    background-color: #EFF3F8;
    border: 1px solid #C5D0DC;
}

$tab-color-blue: #7DB4D8;
//$tab-color-green:#87B87F;
//$tab-color-orange:#F39C12;

.nav-tabs[class*="tab-color-"] > li > a {
    &, &:focus, &:hover {
        color: #FFF;
        border-color: transparent;
        margin-right: 3px;
    }

    > .badge {
        border-radius: 2px;
    }
}

.nav-tabs[class*="tab-color-"] > li:not(.active) > a {
    &:hover {
        opacity: 0.85;
        border-color: rgba(0,0,0,0.15);
        border-bottom-color: transparent;
    }

    > [class*="icon-"]:first-child {
        color: #FFF !important;
    }

    > .badge {
        color: rgba(0,0,0,0.4) !important;
        background-color: #FFF !important;
        border-radius: 2px;
    }
}

.nav-tabs.tab-color-blue > li > a {
    &, &:focus {
        background-color: $tab-color-blue;
    }
}

.nav-tabs[class*="tab-color-"] > li.active > a {
    &, &:focus, &:hover {
        background-color: #FFF;
        color: darken(desaturate($tab-color-blue, 20%), 20%);
        box-shadow: none;
    }
}

.nav-tabs.tab-color-blue > li.active > a {
    &, &:focus, &:hover {
        color: darken(desaturate($tab-color-blue, 25%), 25%);
    }
}

.nav-tabs.tab-color-blue > li.active > a {
    &, &:focus, &:hover {
        border-color: $tab-color-blue $tab-color-blue transparent;
    }
}

.nav-tabs.tab-color-blue {
    border-bottom-color: #C5D0DC;
}

.tabbed-section-with-title {
    & > h2 {
        float: left;
        height: 24px;
        font-size: 18px;
        line-height: 24px;
        margin: 0px;
    }

    & > ul.nav-tabs {
        @extend .pull-right;
        margin-right: 4px;
    }

    @include clearfix();
}