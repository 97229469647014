@use "sass:math";

.sidebar {
    position: fixed;
    background-color: $sidebar-bg;
    box-shadow: $sidebar-shadow;
    height: calc(100% - #{$header-height - 5});
    top: $header-height - 5;
    width: $sidebar-width;
    transform: translate3d(-$sidebar-width, 0, 0);
    @include transition(all 300ms);
    z-index: 1020;
    opacity: 0;
    overflow-y: auto;
    padding: 0 $sidebar-side-margin;

    &.toggled {
        opacity: 1;
        transform: translate3d(0, 0, 0);
    }

    a:hover, a:active {
        text-decoration: none;
    }

    ul.nav {
        display: block;
    }
}

body {
    // &:not(.sw-toggled) {
    // 	.sidebar {
    // 		//box-shadow: 0 0 10px rgba(51, 51, 51, 0.38);
    // 		//background-color: white;
    // 	}
    // }

    &.show-sidebar {
        .sidebar {
            @include media-breakpoint-up(lg) {
                transform: translate3d(0, 0, 0);
                opacity: 1;
                //box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15);
            }

            @include media-breakpoint-down(sm) {
                box-shadow: 0 0 10px rgba(51, 51, 51, 0.38);
                background-color: $sidebar-bg;
            }
        }

        #content {
            @include media-breakpoint-up(lg) {
                padding-left: $sidebar-width;
            }

            & > .container {
                @include media-breakpoint-up(lg) {
                    width: calc(100% - 30px);
                }
            }
        }
    }
}

.nav-list {
    margin: 0;
    padding: 0;
    list-style: none;

    & > li > a, & > .btn {
    }
}

.nav-list > li > a, .nav-list .nav-header {
    margin: 0;
}

.nav-list > li {
    padding: 0;
    margin: 0;
    border: none;
    border-top: $menu-item-border-top-width solid #FCFCFC;
    border-bottom: $menu-item-border-bottom-width solid $menu-item-border-color;
    box-shadow: $menu-item-box-shadow;

    & > .section {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        height: $menu-item-height;
        position: relative;

        & > .dropdown-indicator {
            width: 1.5em;
            color: $menu-subarrow-color;
            font-size: $menu-icon-size;
            background-color: $menu-item-bg;
            border: none;

            & > i {
                transition: transform 0.35s ease;
            }

            &[aria-expanded="true"] > i {
                transform: rotate(-90deg);
            }

            &:focus {
                outline: none;
            }
        }

        & > a {
            flex-grow: 1;
            line-height: $menu-item-height - 2;
            padding: 0 0 0 7px;
            background-color: $menu-item-bg;
            color: $menu-item-color;
            -webkit-text-shadow: none !important;
            text-shadow: none !important;
            font-size: $menu-font-size;
            text-decoration: none;

            > i:first-child {
                display: inline-block;
                vertical-align: middle;
                width: $menu-icon-width;
                text-align: center;
                font-size: $menu-icon-size;
                font-weight: normal;
            }

            &:focus {
                background-color: $menu-item-bg;
                color: $menu-focus-color;
            }

            &:hover {
                color: $menu-focus-color;
                background-color: $menu-item-hover-bg;

                &:before {
                    display: block;
                    content: "";
                    position: absolute;
                    top: -1px;
                    bottom: 0;
                    left: 0;
                    width: 3px;
                    max-width: 3px;
                    overflow: hidden;
                    background-color: $menu-item-hover-indicator;
                }
            }
        }
    }

    &:first-child {
        border-top: none;
    }

    &.active {
        background-color: $menu-item-active-bg;

        > a {
            &, &:hover, &:focus, &:active {
                background-color: $menu-item-active-bg;
                color: $menu-item-active-color;
                font-weight: bold;
                font-size: $menu-font-size;
            }

            > i {
                font-weight: normal;
            }

            &:hover:before { /* no left side menu item border on active state */
                display: none;
            }
        }

        > .dropdown-indicator {
            background-color: $menu-item-active-bg;
        }
        //////
        &:after { /* the border on right of active item */
            display: inline-block;
            content: "";
            position: absolute;
            right: -2px;
            top: -1px;
            bottom: 0;
            border-right: 2px solid $menu-item-active-border-right;
        }
    }

    .submenu {
        //display: none;
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        background-color: $submenu-bg;
        border-top: 1px solid $submenu-border;
        margin-top: $submenu-margin-top;

        > li {
            margin-left: 0;
            position: relative;
            font-size: $submenu-font-size;

            > a {
                display: block;
                position: relative;
                color: $submenu-item-color;
                height: $submenu-item-height;
                line-height: $submenu-item-height - 2;
                padding: 0 0 0 7px;
                margin: 0;
                border-top: 1px dotted $submenu-item-border;

                &:focus {
                    text-decoration: none;
                }

                &:hover {
                    text-decoration: none;
                    color: $submenu-item-hover;
                    background-color: $submenu-item-hover-bg;
                }
            }

            &.active > a {
                color: $menu-item-active-color;
            }
            /* optional icon before each item */
            a > i:first-child {
                display: inline-block;
                font-size: $submenu-icon-size;
                font-weight: normal;
                width: $submenu-icon-width;
                height: auto;
                line-height: $submenu-icon-size;
                text-align: center;
                //position: relative;
                //left: -1 * ($submenu-icon-width + 4);
                //top: 11px;
                z-index: 1;
            }

            &.active > a > i:first-child,
            &:hover > a > i:first-child {
                display: inline-block;
            }

            &.active > a > i:first-child {
                color: $submenu-item-active-icon;
            }
        }
        // > li
    }

    &.active {
        > .submenu {
            > li {
                &:before {
                    border-top-color: $submenu-active-left-border;
                }
            }

            &:before {
                border-left-color: $submenu-active-left-border;
            }
        }
    }

    &.active.open li.active.open li.active > a:after {
        top: 0;
    }
}
//end of .nav-list > li

//.nav-list li
.nav-list li {
    @mixin active_state_caret() {
        display: block;
        content: "";
        position: absolute !important;
        right: 0;
        top: 4px;
        border: 8px solid transparent;
        border-width: 14px 10px;
        border-right-color: $menu-item-active-arrow-color;
    }

    .submenu {
        /* needed for webkit based browsers to slideToggle without problem */
        overflow: hidden;
    }

    &.active > a:after {
        @include active_state_caret();
    }

    &.open > a:after { /* no caret for open menu item */ //we put this after .active > a:after to override it
        display: none;
    }

    &.active.open > .submenu > li.active.open > a.dropdown-toggle:after {
        /* don't display caret on active open item who is open and has children */
        display: none;
    }

    &.active > .submenu > li.active > a:after {
        /** don't display caret on active item whose parent is not open
	useful for hiding the caret when submenu is sliding up */
        display: none;
    }

    &.active.open > .submenu > li.active > a:after {
        /* display caret on active item whose parent is open */
        display: block;
    }

    &.active.no-active-child {
        > a:after { /* show caret for active menu item with childs which is not open(i.e. no submenu item is active) */
            display: inline-block !important;
        }
    }
}
//end of .nav-list li

.nav-list a {
    .badge, .label {
        font-size: $menu-badge-font-size;
        padding-left: 6px;
        padding-right: 6px;
        position: absolute;
        top: 9px;
        right: 11px;
        opacity: 0.88;

        i {
            vertical-align: middle;
            margin: 0;
        }
    }

    &.dropdown-toggle {
        .badge, .label {
            right: 28px;
        }
    }

    &:hover {
        .badge, .label {
            opacity: 1;
        }
    }
}

.nav-list .submenu .submenu a {
    .badge, .label {
        top: 6px;
    }
}

.nav-list.tree {
    > li {
        .submenu { //the first level submenu
            > li {
                //tree like menu
                &:before {
                    // The Horizontal line
                    content: "";
                    display: inline-block;
                    position: absolute;
                    width: 7px;
                    left: 20px;
                    top: math.div($submenu-item-height, 2);
                    border-top: 1px $submenu-left-border-style $submenu-left-border;
                }

                > a {
                    padding-left: 34px;
                }
            }

            &:before {
                // The Vertical line
                content: "";
                display: block;
                position: absolute;
                z-index: 1;
                left: 18px;
                top: 0;
                bottom: math.div($submenu-item-height, 2) - 1;
                border-left: 1px $submenu-left-border-style $submenu-left-border;
            }
        }
    }
}

/* sidebar shortcuts icon */
.sidebar-shortcuts {
    margin-top: 5px;
    background-color: $shortcuts-bg;
    border-bottom: 1px solid $shortcuts-border;
    text-align: center;
    line-height: 37px;
    max-height: 40px;
    margin-bottom: 0;

    .btn-sm {
        padding: 5px 10px;
    }
}

.sidebar-shortcuts-large {
    padding-bottom: 4px;

    > .btn > i {
        font-size: 16px;
        line-height: 20px;
    }
}

/* side menu toggler in mobile view */
.menu-toggler {
    display: none;
}