.progress {

    position: relative;

    .progress-bar {
        height: 100%;
    }

    span {
        position: absolute;
        top: 0px;
        left: 0px;
        bottom: 0px;
        right: 0px;
        
        color: black;
        text-align: center;
        //width: 100%;
        line-height: 1.6;
    }
}

//td .progress span { line-height: 1.6; }